import { Component } from 'react';

//Utils, component with all the utils or functions of the web app
class Utils extends Component{
    static NAME_CUSTOMER = 'Ticalo';
    static VERSION_WEBAPP = '1.0';
    static WEBSITE_CUSTOMER = process.env.REACT_APP_WEBSITE_CUSTOMER;
    static SLUG_ORGANIZER = process.env.REACT_APP_ORGANIZER;
    static NUM_RATE = 10;
    static KEY_WEB = process.env.REACT_APP_KEYWEB;

    static TicketSingle = 'Ticket individuale';
    static TicketModularSubscription = 'Biglietto Abbonamento';
    static TicketFixedSubscription = 'Abbonamento fisso';
    static ArrCategoryEvents = process.env.REACT_APP_CATEGORYEVENTS;

    static SlugTipology = process.env.REACT_APP_SLUGTIPOLOGY;
    static SlugPubblication = process.env.REACT_APP_SLUGPUBBLICATION;
    static SlugEvent = process.env.REACT_APP_SLUGEVENT;
    static SlugMappa = process.env.REACT_APP_SLUGMAPPA;
    static MinItemSelected = process.env.REACT_APP_MIN_ITEM_SELECTED;
    static MaxItemSelected = process.env.REACT_APP_MAX_ITEM_SELECTED;

    static TypeEventSingleWithoutMap = process.env.REACT_APP_TYPEEVENTWITHOUTMAP;
    static TypeEventSingleWithMap = process.env.REACT_APP_TYPEEVENTWITHMAP;
    static TypeEventSubscription = process.env.REACT_APP_TYPEEVENTSUBSCRIPTION;

    //data server ticalo
    static TOKEN_TICALO = process.env.REACT_APP_TOKEN_TICALO;
    static TOKEN_TICALO_OAUTH = process.env.REACT_APP_TOKEN_TICALO_OAUTH;
    static BASE_URL = process.env.REACT_APP_BASE_URL;
    static URL_API_SERVER = Utils.BASE_URL + process.env.REACT_APP_BASE_URL_API_SERVER;
    static URL_API_SERVER_ORGANIZER = Utils.URL_API_SERVER + process.env.REACT_APP_URL_API_SERVER_ORGANIZER;
    //static URL_API_SERVER_ORGANIZER_EVENTS = Utils.URL_API_SERVER_ORGANIZER + process.env.REACT_APP_URL_API_SERVER_ORGANIZER_EVENTS;
    static URL_API_SERVER_ORGANIZER_EVENTS = process.env.REACT_APP_URL_API_SERVER_ORGANIZER_EVENTS;
    static URL_API_SERVER_ORGANIZERS = Utils.URL_API_SERVER + process.env.REACT_APP_URL_API_SERVER_ORGANIZERS;

    //data server custom
    static TOKEN_SERVER_CUSTOM = process.env.REACT_APP_TOKEN_TICALO_CUSTOM;
    static BASE_URL_SERVER_CUSTOM = process.env.REACT_APP_BASE_URL_SERVER_CUSTOM;
    static URL_API_SERVER_CUSTOM = Utils.BASE_URL_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM;
    static URL_API_SERVER_CUSTOM_GET_DISPONIBILITA = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_GET_DISPONIBILITA;
    static URL_API_SERVER_CUSTOM_ADD_CART_POSITIONS = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_ADD_CART_POSITIONS;
    static URL_API_SERVER_CUSTOM_DELETE_CART_POSITIONS = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_DELETE_CART_POSITIONS;
    static URL_API_SERVER_CUSTOM_POST_CART_POSITIONS = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_POST_CART_POSITIONS;
    static URL_API_SERVER_CUSTOM_INVIA_EMAIL_SUPPORTO = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_INVIA_EMAIL_SUPPORTO;

    static URL_API_SERVER_CUSTOM_CHECK_BIGLIETTI = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_CHECK_BIGLIETTI;
    static URL_API_SERVER_CUSTOM_POST_PRENOTAZIONE_ABBONAMENTO = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_POST_PRENOTAZIONE_ABBONAMENTO;
    static URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_CON_MAPPA = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_CON_MAPPA;
    static URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_SENZA_MAPPA = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_SENZA_MAPPA;
    static URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_LISTA_EVENTI = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_LISTA_EVENTI;
    static URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_CATEGORIA = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_CATEGORIA;
    static URL_API_SERVER_CUSTOM_GET_TIPO_TITOLI = Utils.URL_API_SERVER_CUSTOM + process.env.REACT_APP_URL_API_SERVER_CUSTOM_GET_TIPO_TITOLI;


    //array tipi titoli
    static TYPE_TITLE = {
        'I1': 'Intero',
        'R1': 'Ridotto militari/ragazzi',
        'R2': 'Ridotto militari',
        'R3': 'Ridotto ragazzi',
        'R4': 'Ridotto anziani',
        'R5': 'Ridotto donna',
        'R6': 'Ridotto circoli',
        'RX': 'Ridotto generico',
        'P1': 'Pomeridiano',
        'O1': 'Omaggio militari/ragazzi',
        'O2': 'Omaggio militari',
        'O3': 'Omaggio ragazzi',
        'O4': 'Omaggio anziani',
        'O5': 'Omaggio donna',
        'O6': 'Omaggio circoli',
        'OX': 'Omaggio generico',
        'S1': 'Servizio',
        'PB': 'Prestazione obbligatoria',
        'PF': 'Prestazione facoltativa',
        'PO': 'Prestazione omaggio',
        'CB': 'Cessione obbligatoria',
        'CF': 'Cessione facoltativa',
        'CO': 'Cessione omaggio'
    }

    //array tipi proventi
    static TYPE_PROCEEDS = {
        'A1': 'Affitti',
        'AP': 'Appalti di spettacoli e/o intrattenimenti',
        'C1': 'Contributi Enti Pubblici',
        'C2': 'Contributi Enti Pubblici Territoriali',
        'C3': 'Contributi Soggetti Privati',
        'C4': 'Contributi MBAC',
        'CN': 'Cene',
        'CO': 'Consumazioni',
        'CS': 'Corrispettivi per soggiorno',
        'GR': 'Guardaroba',
        'LP': "Libretto/programma dell'opera rappresentata",
        'OA': 'Oblazioni da Partecipanti',
        'ON': 'Oblazioni da non Partecipanti',
        'P04': 'Altre prestazioni di servizi con IVA 4%',
        'P10': 'Altre prestazioni di servizi con IVA 10%',
        'P20': 'Altre prestazioni di servizi con IVA 20%',
        'Pnn': 'Altre prestazioni di servizi con IVA nn%',
        'PC': 'Proventi del gioco soggetti ad imposta intrattenimenti (casinò)',
        'PE': 'Altri proventi esenti IVA',
        'PI': 'Altri proventi non imponibili IVA',
        'PN': 'Altri proventi non soggetti ad IVA ma soggetti ad imposta intrattenimenti',
        'PK': 'Parcheggio',
        'PP': 'Pubblicità',
        'PR': 'Prenotazione tavolo/posto',
        'QS': 'Quote sociali',
        'RR': 'Cessioni diritti radiofonici',
        'RT': 'Cessioni diritti televisivi',
        'SN': 'Sponsorizzazioni in natura',
        'SP': 'Sponsorizzazioni',
        'V04': 'Altre cessioni di beni con IVA 4%',
        'V10': 'Altre cessioni di beni con IVA 10%',
        'V20': 'Altre cessioni di beni con IVA 20%',
        'Vnn': 'Altre cessioni di beni con IVA nn%',
        'PRV' : 'Prevendita'
    }

}

export default Utils;

