import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'moment/locale/it';
import 'moment-timezone';

// Elements
import ListPersonalInfo from "./ListPersonalInfo";
import ButtonWizard from "./ButtonWizard";
import Messages from "../../Messages";
import Functions from "../../Functions";
import Utils from "../../Utils";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";


export default class SelectUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usersSelected: [],
            showModalCheckTickets: false
        };

    }

    componentDidMount() {
        if (typeof(this.props.values.arrUsers) !== 'undefined' && this.props.values.arrUsers !== null){
            if (this.props.values.arrUsers.length > 0){
                this.setState({
                    usersSelected: this.props.values.arrUsers
                });
            }
        }
    }

    continue = (e) => {

        e.preventDefault();
        let eventFinal = null;
        if (typeof(this.props.values.event) !== 'undefined' && this.props.values.event !== null){
            eventFinal = this.props.values.event;
        }
        let eventsSelected = [];
        if (typeof(this.props.values.itemsEventSelected) !== 'undefined' && this.props.values.itemsEventSelected !== null){
            eventsSelected = this.props.values.itemsEventSelected;
        }
        let maxItemsForOrder = 0;
        if (typeof(this.props.values.maxItemsForOrder) !== 'undefined' && this.props.values.maxItemsForOrder !== null){
            maxItemsForOrder = this.props.values.maxItemsForOrder;
        }
        if (this.state.usersSelected.length > 0){
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            let checkData = true;
            for (var i = 0; i < this.state.usersSelected.length; i++) {
                let user = this.state.usersSelected[i];
                if (this.props.values.typeTicket === Utils.TicketModularSubscription || this.props.values.typeTicket === Utils.TicketFixedSubscription){
                    if (user.name === "" || user.surname === "" || user.dateOfBirth === "" || user.placeOfBirth === "" || user.email === "" || user.phone === ""){
                        checkData = false;
                        break;
                    }
                }else{
                    if (this.props.values.event.jsonSettings.attendee_names_asked){
                        if (user.name === "" || user.surname === "" || user.dateOfBirth === "" || user.placeOfBirth === ""){
                            checkData = false;
                            break;
                        }
                    }
                    if (this.props.values.event.jsonSettings.attendee_emails_asked){
                        if (user.email === ""){
                            checkData = false;
                            break;
                        }
                    }
                    if (this.props.values.event.jsonSettings.order_phone_asked){
                        if (user.phone === ""){
                            checkData = false;
                            break;
                        }
                    }
                }
            }

            if (checkData){
                if (this.props.values.typeTicket === Utils.TicketSingle){
                    if (eventFinal.jsonSettings.attendee_names_asked){
                        this.checkTickets(this.state.usersSelected, eventFinal, eventsSelected, maxItemsForOrder);
                    }else{
                        this.props.nextStep(this.props.values.ticketsSelected, this.props.values.totalAmount, eventFinal, this.props.values.organizer, this.state.usersSelected, this.props.values.dateTimer, this.props.values.arrCartPositions, this.props.values.cartPositions, eventsSelected, maxItemsForOrder);
                    }
                }else{
                    this.props.nextStep(this.props.values.ticketsSelected, this.props.values.totalAmount, eventFinal, this.props.values.organizer, this.state.usersSelected, this.props.values.dateTimer, this.props.values.arrCartPositions, this.props.values.cartPositions, eventsSelected, maxItemsForOrder);
                }
            }else{
                Functions.alertMsgError(Messages.MsgCompileAllTickets);
            }
        }else{
            if (!this.props.values.event.jsonSettings.attendee_names_asked && !this.props.values.event.jsonSettings.attendee_emails_asked && !this.props.values.event.jsonSettings.order_phone_asked){
                this.props.nextStep(this.props.values.ticketsSelected, this.props.values.totalAmount, eventFinal, this.props.values.organizer, this.state.usersSelected, this.props.values.dateTimer, this.props.values.arrCartPositions, this.props.values.cartPositions, eventsSelected, maxItemsForOrder);
            }else{
                Functions.alertMsgError(Messages.MsgCompileAllTickets);
            }
        }


    };

    back = (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.props.prevStep();
    };

    getUsersSelected = (arr) => {
        this.setState({
            usersSelected: arr
        });
    };

    checkTickets(arrUsers, eventFinal, eventsSelected, maxItemsForOrder){

        let params = {};
        params = {
            'eventoSlug': this.props.values.event.slug,
            'organizzatoreSlug': this.props.values.organizer.slug};

        let arrTickets = [];
        for (var i=0; i < arrUsers.length; i++) {
            let objTicket = {};
            objTicket['nome'] = arrUsers[i].name;
            objTicket['cognome'] = arrUsers[i].surname;
            objTicket['id'] = i;
            objTicket['dataNascita'] = arrUsers[i].dateOfBirth;
            objTicket['luogoNascita'] = arrUsers[i].placeOfBirth;
            arrTickets.push(objTicket);
        }
        params['singoloCheckModelList'] = arrTickets;

        const paramsString = JSON.stringify(params);
        let headers = {
            method: 'POST',
            body: paramsString,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
                'Host': Utils.BASE_URL_SERVER_CUSTOM
            }
        };

        this.setState(
            {
                showModalCheckTickets: true
            }
        );

        let link = Utils.URL_API_SERVER_CUSTOM_CHECK_BIGLIETTI;

        fetch(link, headers)
            .then((response) => {
                if(!response.ok) throw response;
                else return response;
            })
            .then((response) => {
                this.setState(
                    {
                        showModalCheckTickets: false
                    }
                );
                if (response.status === 200){
                    this.props.setUsersDuplicate([]);
                    this.props.nextStep(this.props.values.ticketsSelected, this.props.values.totalAmount, eventFinal, this.props.values.organizer, this.state.usersSelected, this.props.values.dateTimer, this.props.values.arrCartPositions, this.props.values.cartPositions, eventsSelected, maxItemsForOrder);
                }else{
                    Functions.alertMsgError(Messages.MsgErrorGeneral);
                }
            })
            .catch(async (response) => {
                this.setState(
                    {
                        showModalCheckTickets: false
                    }
                );
                if (response.status === 406){
                    let json = await response.json();
                    this.props.setUsersDuplicate(json);
                    Functions.alertMsgError(Messages.MsgDuplicateUser);
                }else{
                    Functions.alertMsgError(Messages.MsgErrorServer);
                }
            });

    }

    renderTotTicket(){
        const { values } = this.props;

        if (values.typeTicket === Utils.TicketModularSubscription || values.typeTicket === Utils.TicketFixedSubscription){
            //funzionalità da implementare
        }else{
            if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                return <Badge bg="info" className="fs-title-secondary-14 mb-10"><i className="fa fa-chair"></i>&nbsp;Posti selezionati: {values.arrCartPositions.length}</Badge>
            }else{
                return <Badge bg="info" className="fs-title-secondary-14 mb-10"><i className="fa fa-ticket-alt"></i>&nbsp;Biglietti selezionati: {values.ticketsSelected.length}</Badge>
            }
        }
    }

    renderEvent(){
        return <aside className="sticky-top pb-1">
            <div className="widget">
                <ul className="service-menu">
                    <h6>Evento</h6>
                    <p className="p-card-thick">{this.props.values.event.name.it}</p>
                    <img src={this.props.values.event.imgEvent !== ""  ? this.props.values.event.imgEvent : imgPlaceholder}
                         className="img-event-sidebar" alt="immagine dell'organizzatore" />
                    <p className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;
                        {Functions.convertDate(this.props.values.event.date_from)}
                    </p>
                    <p className="title1-modal-event">Tipologia evento:&nbsp;</p>
                    <p className="title2-modal-event">{this.props.values.event.tipology}</p>
                </ul>
            </div>
        </aside>
    }

    renderOrganizer(){
        const mailto = "mailto:" + this.props.values.organizer.mailOrganizer;
        return <aside className="sticky-top pb-1">
            <div className="widget">
                <ul className="service-menu">
                    <h6>Organizzatore</h6>
                    <p className="p-card-thick">{this.props.values.organizer.name}</p>
                    <img src={this.props.values.organizer.imgOrganizer !== ""  ? this.props.values.organizer.imgOrganizer : imgPlaceholder}
                         className="img-event-sidebar" alt="immagine dell'organizzatore" />
                    <p className="title1-modal-event">Contatti:&nbsp;</p>
                    <p className="title2-modal-event truncate-event"><a href={mailto}>{this.props.values.organizer.mailOrganizer}</a></p>
                </ul>
            </div>
        </aside>
    }

    render() {
        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <Modal
                        size="lg"
                        show={this.state.showModalCheckTickets}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>Stiamo verificando i tuoi dati...</Modal.Body>
                        <Spinner className="center-spinner-loader" animation="border" role="status" variant="primary" >
                            <span className="visually-hidden">Caricamento...</span>
                        </Spinner>
                    </Modal>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <ListPersonalInfo values={this.props.values} sendUsers={this.getUsersSelected} />
                                    </div>
                                    <div className="col-lg-3">
                                        {
                                            this.props.values.typeTicket === Utils.TicketModularSubscription || this.props.values.typeTicket === Utils.TicketFixedSubscription
                                                ?(
                                                    this.renderOrganizer()
                                                )
                                                : this.renderEvent()
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.back} text="Indietro" />
                        <ButtonWizard btnClicked={this.continue} text="Avanti" />
                    </div>

                </fieldset>
            </>
        );

    }

}
