import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Elements
import ButtonWizard from "./ButtonWizard";
import PaymentInfo from "./PaymentInfo";
import Functions from "../../Functions";
import Utils from "../../Utils";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

export default class SelectPayment extends Component {

    componentDidMount() {

    }

    back = (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.props.prevStep();
    };

    renderTotTicket(){
        if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
            return <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Posti selezionati: {this.props.values.arrCartPositions.length}</p>
        }else{
            return <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Biglietti selezionati: {this.props.values.ticketsSelected.length}</p>
        }
    }

    renderEvent(){
        return <aside className="sticky-top pb-1">
            <div className="widget">
                <ul className="service-menu">
                    <h6>Evento</h6>
                    <p className="p-card-thick">{this.props.values.event.name.it}</p>
                    <img src={this.props.values.event.imgEvent !== ""  ? this.props.values.event.imgEvent : imgPlaceholder}
                         className="img-event-sidebar" alt="immagine dell'evento" />
                    <p className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;
                        {Functions.convertDate(this.props.values.event.date_from)}
                    </p>
                    <p className="title1-modal-event">Tipologia evento:&nbsp;</p>
                    <p className="title2-modal-event">{this.props.values.event.tipology}</p>
                    {this.renderTotTicket()}
                    <p className="fs-title-secondary-14 text-color-secondary mt-7 align-left-base">Totale: {this.props.values.totalAmount} €</p>
                </ul>
            </div>
        </aside>
    }

    renderOrganizer(){
        const mailto = "mailto:" + this.props.values.organizer.mailOrganizer;

        return <aside className="sticky-top pb-1">
            <div className="widget">
                <ul className="service-menu">
                    <h6>Organizzatore</h6>
                    <p className="p-card-thick">{this.props.values.organizer.name}</p>
                    <img src={this.props.values.organizer.imgOrganizer} className="img-event-sidebar" alt="immagine dell'organizzatore" />
                    <p className="title1-modal-event">Contatti:&nbsp;</p>
                    <p className="title2-modal-event truncate-event"><a href={mailto}>{this.props.values.organizer.mailOrganizer}</a></p>
                    <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Posti selezionati: {this.props.values.arrCartPositions.length}</p>
                    <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Eventi selezionati: {this.props.values.itemsEventSelected.length}</p>
                    <p className="fs-title-secondary-14 text-color-secondary mt-7 align-left-base">Totale: {this.props.values.totalAmount} €</p>
                </ul>
            </div>
        </aside>
    }

    render() {

        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <PaymentInfo values={this.props.values}/>
                                    </div>
                                    <div className="col-lg-3">
                                        {
                                            this.props.values.typeTicket === Utils.TicketModularSubscription || this.props.values.typeTicket === Utils.TicketFixedSubscription
                                                ?(
                                                    this.renderOrganizer()
                                                )
                                                : this.renderEvent()
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.back} text="Indietro" />
                    </div>

                </fieldset>
            </>
        );

    }

}
