import React, { useEffect, useState, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Seat from "./Seat";
import SeatPopup from "./SeatPopup";
import { useWindowSize } from './windowSize';
import Utils from "../../../Utils";
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Functions from "../../../Functions";
import DataManager from "../../../DataManager";
import Messages from "../../../Messages";
import Form from 'react-bootstrap/Form';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import FilterDropdown from "./FilterDropdown";

//images
import theaterEvent from "../../../../images/icon/theater-event.png";

//SeatMap, component for managing the interactive map
const SeatMap = (props) => {

	const {
		handleHover,
		checkIsSelected,
		checkAvailability,
		mapData,
		selectedSeats,
		handleSelect,
		handleDeselect,
		findColorByCategory,
		selectedFilter
	} = props;

	const renderCell = (cell, i, total, seat_rows_number, seat, row) => {
		if (!seat) {
			return null;
		}
		cell = Number(cell);

		const isIBSRow = () => String(row?.seats[0]?.seat_number).length <= 3

		return <>
			{i === 0  ?
				<span
					className="margin-h"
					id={cell === total / 2 + 1 ? "margin-left" : ""}
					style={row.row_number_position !== 'end' ? { position: 'absolute', left: String(seat_rows_number).length > 1 ? seat.position?.x - 23 : seat.position?.x - 15, top: seat.position?.y - 5 } : { position: 'absolute', left: seat.position?.x, top: seat.position?.y + 18, opacity: 0 }}
				>
          {seat_rows_number}
        </span> : null
			}
			<Seat
				lengthSeat={seat.seat_number.length}
				key={seat.seat_guid}
				seatId={seat.seat_guid}
				status={checkAvailability(seat, mapData)}
				seat={seat}
				onHover={handleHover}
				rowNumber={seat_rows_number}
				onSelect={handleSelect}
				position={seat.position}
				isIBSRow={isIBSRow}
				onDeselect={handleDeselect}
				isSelected={checkIsSelected(seat, selectedSeats)}
				color={findColorByCategory(seat.category)}
			/>
			{i === total - 1  && isIBSRow() ?
				<span
					className="margin-h"
					id={cell === total / 2 + 1 ? "margin-left" : ""}
					style={row.row_number_position !== 'end' ? { position: 'absolute', left: seat.position?.x + 18, top: seat.position?.y - 5, opacity: 0 } : { position: 'absolute', left: seat.position?.x, top: seat.position?.y - 23, opacity: 0 }}
				>
          {seat_rows_number}
        </span> : null
			}
		</>
	};

	const renderCategory3Row = (rows) => {
		let seats = rows.seats;
		let seat_rows_number = rows.row_number;
		const position = rows.position;
		const isIBSRow = () => String(seats[0]?.seat_number).length <= 3
		return (
			<div className="seats_row" style={{
				position: 'absolute',
				top: position.y,
				left: !isIBSRow() ? position.x : position.x - 10,
				// width: '100%'
			}}>
				{seats.map((seat, i) => renderCell(
					seat?.seat_number,
					i,
					seats.length,
					seat_rows_number,
					seat,
					rows
					)
				)}
			</div>
		);
	};

	const renderCategorySeats = () => {
		const map = mapData.mappa.layout;
		if (selectedFilter !== 'All') {
			const filteredZone = map.zones.find(z => z.name === selectedFilter)
			return (
				<>
					{
						filteredZone.rows.map(row =>
							renderCategory3Row(row)
						)
					}
				</>
			)
		}
		return (
			<>
				{map.zones.map(zone => (
					zone.rows.map(row =>
						renderCategory3Row(row)
					)
				))}
			</>
		);
	};

	if (!mapData) return null;

	return (
		<div className="map_container" style={{ width: mapData.mappa.layout.size.width, height: mapData.mappa.layout.size.height, position: 'relative' }}>
			{renderCategorySeats()}
			{mapData.mappa.layout.zones.map(zone => (
				zone.areas.map(area => (
					area.shape === 'text' ? <div style={{ position: 'absolute', left: area.position.x, top: area.position.y, transform: `rotate(${area.rotation}deg)` }}>
						<p className="category_heading" style={{ position: 'absolute', left: area.text.position.x -40, top: area.text.position.y - 15.0, color: area.text.color, fontSize: area.text.size }}> {area.text.text}</p></div> : area.shape === "rectangle" ? <div className="category_color" style={{ position: 'absolute', left: area.position.x, top: area.position.y + 10.0, width: area.rectangle.width, height: area.rectangle.height, backgroundColor: area.color, border: `1px solid ${area.border_color}`, transform: `rotate(${area.rotation}deg)`  }}>
						{area.text.text ? <p style={{ color: area.text.color, fontSize: area.text.size, textAlign: "center"}}>{area.text.text}</p> : null}
					</div> : null
				))
			))}
		</div>
	);
};



const ZoomContent = (props) => {

	const {width} = useWindowSize();
	const [popup, setPopup] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [selectedSeats, setSelectedSeats] = React.useState([]);

	const [isLoaded, setLoaded] = useState(false);
	const [msgError, setError] = useState("");
	const [arrSlugList, setSlugList] = React.useState([]);
	const [totalAmount, setTotalAmount] = useState(0.0);
	const [totalSeat, setTotalSeat] = useState(0);
	const [cartPositionsList, setCartPositionsList] = React.useState([]);
	const [showModal, setShowModal] = useState(false);
	const [msgModal, setMsgModal] = useState("");

	const findColorByCategory = category => {
		const findMatched = mapData.mappa.layout.categories.find(c => c.name === category);
		return findMatched?.color;
	};

	useEffect(() => {
		const arrTypeTicket = JSON.parse(localStorage.getItem("arrTypeTicket"));
		if (typeof (arrTypeTicket) === 'undefined' || arrTypeTicket === null) {
			loadTypeTicket();
		}
		if(props.values.arrCartPositions.length > 0){
			let arrSlug = [];
			if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
				setCartPositionsList(props.values.cartPositions);
				setTotalSeat(props.values.cartPositions.length);
				props.sendcartpositions(props.values.cartPositions);
				if (props.values.itemsEventSelected.length > 0){
					for (var i=0; i < props.values.itemsEventSelected.length; i++) {
						arrSlug.push(props.values.itemsEventSelected[i].slug);
					}
					setSlugList(arrSlug);
				}
			}else{
				if (typeof (props.values.event) !== 'undefined' && props.values.event !== null) {
					arrSlug.push(props.values.event.slug);
				}
				setSlugList(arrSlug);
				setCartPositionsList(props.values.arrCartPositions);
				setTotalSeat(props.values.arrCartPositions.length);
			}
			setTotalAmount(props.values.totalAmount);
			const selectedSeatsStored = JSON.parse(localStorage.getItem("selectedSeats"));
			if (selectedSeatsStored?.length) {
				setSelectedSeats(props.values.arrCartPositions);
				props.sendseats(props.values.arrCartPositions, props.values.totalAmount);
			}
			const map = JSON.parse(localStorage.getItem("mapEvent"));
			setLoaded(true);
			setMapData(map);

		}else{
			loadMap("", []);
		}
		window.onbeforeunload = function(event) {
			localStorage.removeItem('selectedSeats');
			localStorage.removeItem('mapEvent');
			loadMap("", []);
		};

	}, [])

	const loadMap = React.useCallback((seatGuid, arrSelectedSeats) => {
		let arrSlug = [];
		if (typeof (props.values.event) !== 'undefined' && props.values.event !== null) {
			arrSlug.push(props.values.event.slug);
		}else{
			if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
				for (var i=0; i < props.values.itemsEventSelected.length; i++) {
					arrSlug.push(props.values.itemsEventSelected[i].slug);
				}
			}
		}

		setSlugList(arrSlug);
		if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
			DataManager.getAvailabilityMap(props.values.organizer.slug, arrSlug).then((result) => {
				if (result !== null) {
					const arrMap = result[0].map;
					if (typeof (arrMap) !== 'undefined' && arrMap !== null) {
						setTimeout(()=>{
							localStorage.setItem("mapEvent", JSON.stringify(arrMap));
							setLoaded(true);
							setMapData(arrMap);
						},500)
					} else {
						setLoaded(false);
						setError(Messages.MsgErrorMap);
					}
				}else{
					setLoaded(false);
					setError(Messages.MsgErrorGeneral);
				}
			});
		}else{
			DataManager.getAvailabilityMapByCategory(props.values.organizer.slug, arrSlug).then((result) => {
				if (result !== null) {
					const arrMap = result[0].map;
					if (typeof (arrMap) !== 'undefined' && arrMap !== null) {
						setTimeout(()=>{
							localStorage.setItem("mapEvent", JSON.stringify(arrMap));
							setLoaded(true);
							setMapData(arrMap);
						},500)
					} else {
						setLoaded(false);
						setError(Messages.MsgErrorMap);
					}
				}else{
					setLoaded(false);
					setError(Messages.MsgErrorGeneral);
				}
			});
		}

	}, [selectedSeats, isLoaded, msgError]);

	const loadTypeTicket = React.useCallback(() => {
		DataManager.getTypeTicket().then((result) => {
			if (result !== null) {
				const arrTypeTicket = result[0].typeTicket;
				if (typeof (arrTypeTicket) !== 'undefined' && arrTypeTicket !== null) {
					setTimeout(()=>{
						localStorage.setItem("arrTypeTicket", JSON.stringify(arrTypeTicket));
					},500)
				} else {
					console.log("error typeTicket")
				}
			}else{
				console.log("error typeTicket")
			}
		});
	}, []);

	const handleHover = React.useCallback((seat, pos) => {
		setPopup({
			seat: seat,
			position: pos
		});
	}, []);

	const handleSelect = React.useCallback(
		seat => {

			if (typeof (mapData) !== 'undefined' && mapData !== null) {
				if (typeof (mapData['seatsItemsModelMap']) !== 'undefined' && mapData['seatsItemsModelMap'] !== null){
					let arr = mapData['seatsItemsModelMap'];
					let arrTemp = arr[seat.category];
					let itemHashMap = arrTemp.itemHashMap;
					if (itemHashMap !== null && itemHashMap.length > 0){
						for (var v=0; v < itemHashMap.length; v++) {
							let obj = itemHashMap[v];
							if (typeof (obj.sales_channels) !== 'undefined' && obj.sales_channels !== null && obj.sales_channels.length > 0){
								const checkWeb = obj.sales_channels.every(item => item === Utils.KEY_WEB);
								if (checkWeb) {
									seat.typeTicket = itemHashMap[v];
									break;
								}
							}

						}
					}
				}
			}

			if (typeof (seat.typeTicket) !== 'undefined' && seat.typeTicket !== null) {
				if(props.values.countDownFinish !== false) {
					let totSelectedSeats = selectedSeats.length;
					let maxItems = "";
					if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
						maxItems = props.values.maxItemsForOrder
					}else{
						maxItems = props.values.event.jsonSettings.max_items_per_order
					}

					if (totSelectedSeats < maxItems){
						let params = {'eventoSlugList': arrSlugList, 'organizer': props.values.organizer.slug, 'seatGuid': seat.seat_guid};
						const paramsString = JSON.stringify(params);

						let headers = {
							method: 'POST',
							body: paramsString,
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
								'Host': Utils.BASE_URL_SERVER_CUSTOM
							}
						};

						let link = Utils.URL_API_SERVER_CUSTOM_ADD_CART_POSITIONS;

						setShowModal(true);
						setMsgModal("Stiamo bloccando il posto...");

						fetch(link, headers)
							.then((response) => {
								if(!response.ok) throw response.status;
								else return response.json();
							})
							.then((responseJson) => {

								if (typeof (mapData) !== 'undefined' && mapData !== null) {
									if (typeof (mapData['seatsItemsModelMap']) !== 'undefined' && mapData['seatsItemsModelMap'] !== null){

										let arr = mapData['seatsItemsModelMap'];
										let arrTemp = arr[seat.category];
										let itemHashMap = arrTemp.itemHashMap;
										if (itemHashMap !== null){
											if(itemHashMap.length > 0){
												if (totalSeat === 0){
													let presale = 0;
													if (typeof (seat.typeTicket.bundles) !== 'undefined' && seat.typeTicket.bundles !== null && seat.typeTicket.bundles.length > 0) {
														presale = seat.typeTicket.bundles[0].designated_price;
													}
													setTotalAmount(itemHashMap[0].default_price + presale);
												}
											}
										}
										seat.cartPositions = responseJson.cartPositionsEventModelList;
									}
								}

								if (typeof (responseJson.cartPositionsEventModelList) !== 'undefined' && responseJson.cartPositionsEventModelList !== null && responseJson.cartPositionsEventModelList.length > 0){
									const newSeats = selectedSeats.concat([seat]);
									if (newSeats.length > 0){
										setSelectedSeats(newSeats);
										props.handleChange?.("selectedSeats");
										localStorage.setItem("selectedSeats", JSON.stringify(newSeats));

										let floatFinal = 0;
										if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){

											if (typeof (responseJson.totalAmount) !== 'undefined' && responseJson.totalAmount !== null){
												const floatTotal = parseFloat(responseJson.totalAmount).toFixed(2);
												floatFinal = floatTotal;

												let v1 = totalAmount*100;
												let v2 = floatTotal*100;
												let totalV1V2 = (v1 + v2)/100;
												const total = parseFloat(totalV1V2.toString()).toFixed(2);
												props.sendseats(newSeats, total);
												setTotalAmount(total);
											}else{
												Functions.alertMsgError(Messages.MsgErrorTotal);
											}

										}else{
											let total = 0;
											for (var i=0; i < newSeats.length; i++) {
												let seat = newSeats[i];
												let presale = 0;
												if (typeof (seat.typeTicket.bundles) !== 'undefined' && seat.typeTicket.bundles !== null && seat.typeTicket.bundles.length > 0) {
													presale = seat.typeTicket.bundles[0].designated_price * 100;
												}
												const floatTotal = parseFloat(seat.typeTicket.default_price).toFixed(2);
												let v1 = total*100;
												let v2 = floatTotal*100;
												let totalV1V2 = (v1 + v2 + presale)/100;
												total = parseFloat(totalV1V2.toString()).toFixed(2);
											}
											props.sendseats(newSeats, total);
											setTotalAmount(total);
										}

										if (totalSeat === 0){
											setTotalSeat(1);
										}else{
											let total = totalSeat + 1;
											setTotalSeat(total);
										}
										if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
											var arrCartPositionsList = {};
											var arrFinal = [];
											if (cartPositionsList.length === 0){
												let arrPositions = responseJson.cartPositionsEventModelList;
												for (var z=0; z < arrPositions.length; z++) {
													let obj = arrPositions[z];
													obj["seatGuid"] = seat.seat_guid;
													obj["categoria"] = seat.category;
													obj["idItem"] = 0;
												}
												arrCartPositionsList[seat.seat_guid] = arrPositions;
												arrCartPositionsList["amount"] = floatFinal;
												arrFinal.push(arrCartPositionsList);
												setCartPositionsList(arrFinal);
											}else{
												arrFinal = cartPositionsList;
												let arrPositions = responseJson.cartPositionsEventModelList;
												for (let x=0; x < arrPositions.length; x++) {
													let obj = arrPositions[x];
													obj["seatGuid"] = seat.seat_guid;
													obj["categoria"] = seat.category;
													obj["idItem"] = 0;
												}
												arrCartPositionsList[seat.seat_guid] = arrPositions;
												arrCartPositionsList["amount"] = floatFinal;
												arrFinal.push(arrCartPositionsList);
												setCartPositionsList(arrFinal);
											}
											props.sendcartpositions(arrFinal);
										}else{
											handleShowSlidebar();
										}
									}else{
										Functions.alertMsgError(Messages.MsgErrorServer);
									}
								}else{
									Functions.alertMsgError(Messages.MsgErrorServer);
								}
								setShowModal(false);

							})
							.catch((error) => {
								setShowModal(false);

								if (error === 499){
									Functions.alertMsgError(Messages.MsgSeatBlocked);
									loadMap(seat.seat_guid, selectedSeats);
								}else{
									Functions.alertMsgError(Messages.MsgErrorServer);
								}
							});

					}else{
						let strErrorItemsSelected = "Errore. Non puoi selezionare più di " + maxItems.toString() + " posti";
						Functions.alertMsgError(strErrorItemsSelected);
					}
				}else{
					Functions.alertMsgError(Messages.MsgTimerExpired);
				}
			}else{
				Functions.alertMsgError(Messages.MsgErrorProductsOnline);
			}


		},
		[selectedSeats, arrSlugList, totalAmount, totalSeat, cartPositionsList, mapData]
	);

	const handleDeselect = React.useCallback(
		seatId => {

			if(props.values.countDownFinish !== false) {
				let checkSeatGuid = false;
				let arrFinalCartPositionsList = [];
				let amountFinal = 0;
				let idxObj = 0;

				if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
					for (let i=0; i < cartPositionsList.length; i++) {
						let keySeatGuid = Object.keys(cartPositionsList[i])[0];
						if (keySeatGuid === seatId.seat_guid){
							checkSeatGuid = true;
							arrFinalCartPositionsList = cartPositionsList[i][seatId.seat_guid];
							amountFinal = cartPositionsList[i]["amount"];
							idxObj = i;
						}
					}
				}else{
					for (let j=0; j < selectedSeats.length; j++) {
						let seatGuid = selectedSeats[j].seat_guid;
						if (seatGuid === seatId.seat_guid){
							checkSeatGuid = true;
							arrFinalCartPositionsList.push(selectedSeats[j].cartPositions[0]);
						}
					}
				}

				if (checkSeatGuid){
					let params = {'cartPositionsEventModelList': arrFinalCartPositionsList, 'organizer': props.values.organizer.slug};
					const paramsString = JSON.stringify(params);

					let headers = {
						method: 'DELETE',
						body: paramsString,
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
							'Host': Utils.BASE_URL_SERVER_CUSTOM
						}
					};

					//let link = 'http://192.168.1.252:8081/siae_bridge_api/api/delete_cart_positions';
					let link = Utils.URL_API_SERVER_CUSTOM_DELETE_CART_POSITIONS;

					setShowModal(true);
					setMsgModal("Stiamo sbloccando il posto...");

					fetch(link, headers)
						.then((response) => response)
						.then((response) => {
							if (response.status === 200){

								const ids = selectedSeats.slice();
								const selectedItem = ids.find(element => element?.uuid === seatId?.uuid);
								ids.splice(ids.indexOf(selectedItem), 1);
								setSelectedSeats(ids);

								let tot = totalAmount;
								let totalAmountFinal = 0;
								if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
									totalAmountFinal = (tot - amountFinal);
									cartPositionsList.splice(idxObj, 1);
									props.sendcartpositions(cartPositionsList);
								}else{
									let presale = 0;
									if (typeof (selectedItem.typeTicket.bundles) !== 'undefined' && selectedItem.typeTicket.bundles !== null && selectedItem.typeTicket.bundles.length > 0) {
										presale = selectedItem.typeTicket.bundles[0].designated_price;
									}
									totalAmountFinal = (tot - selectedItem.typeTicket.default_price - presale);
								}
								const floatTotal = parseFloat(totalAmountFinal.toString()).toFixed(2);
								setTotalAmount(floatTotal);
								props.sendseats(ids , floatTotal);
								if (totalAmountFinal <= 0){
									setTotalAmount(0);
									props.sendseats(ids , 0);
								}

								let totalSeatFinal = totalSeat - 1;
								setTotalSeat(totalSeatFinal);

								localStorage.setItem("selectedSeats", JSON.stringify(ids));

							}else{
								Functions.alertMsgError(Messages.MsgSeatDelete);
							}
							setShowModal(false);
						})
						.catch((error) => {
							setShowModal(false);
							Functions.alertMsgError(Messages.MsgErrorServer);
						});
				}else{
					Functions.alertMsgError(Messages.MsgSeatDelete);
				}
			}else{
				Functions.alertMsgError(Messages.MsgTimerExpired);
			}

		},
		[selectedSeats, arrSlugList, totalAmount, totalSeat, cartPositionsList]
	);

	const checkAvailability = (seat, mapData) => {
		if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
			return mapData?.pretixResponseModel?.available_seats?.find(s => s === seat.seat_guid) ? true : false;
		}else{
			let arr = mapData["seatsItemsModelMap"];
			let dict = [];
			for (var i=0; i < Object.keys(arr).length; i++) {
				let key = Object.keys(arr)[i];
				let arrTemp = arr[key];
				let seatList = arrTemp['seatList'];
				if (seatList.length > 0){
					for (var j=0; j < seatList.length; j++) {
						dict.push(seatList[j])
					}
				}
			}
			return dict.find(s => s === seat.seat_guid) ? true : false;
		}
	};

	const checkIsSelected = (seat, selectedSeats) => {
		return selectedSeats?.find(s => s.seat_guid === seat.seat_guid) ? true : false;
	};

	const renderSectorBySeat = (mapData, seat) => {

		let nameZone = "";
		let colorCategory = "";

		if (mapData !== null){
			for (let z=0; z < mapData.mappa.layout.zones.length; z++) {
				for (let i=0; i < mapData.mappa.layout.zones[z].rows.length; i++) {
					let rowSeats = mapData.mappa.layout.zones[z].rows[i].seats;
					if (rowSeats.length > 0){
						for (let j=0; j < rowSeats.length; j++) {
							let seatGuid = rowSeats[j].seat_guid;
							if (seatGuid === seat.seat_guid){
								nameZone = mapData.mappa.layout.zones[z].name;
							}

						}
					}
				}
			}
			for (let x=0; x < mapData.mappa.layout.categories.length; x++) {
				let nameCategory = mapData.mappa.layout.categories[x].name;
				if (nameCategory === seat.category){
					colorCategory = mapData.mappa.layout.categories[x].color;
				}
			}
		}

		if (nameZone !== ""){
			return <div className="display-flex-container">
				<div className="dot-category" style={{backgroundColor: colorCategory}}></div>
				<p className="title3-modal-event  text-color-primary ml-10 mt-3-neg">Settore&nbsp;{nameZone}</p>
			</div>
		}

	};

	const renderSeatsInSlidebar = (mapData) => {

		if (props.values.typeTicket !== Utils.TicketModularSubscription || props.values.typeTicket !== Utils.TicketFixedSubscription){
			if (totalSeat === 0){
				return <div>
					<Row className="justify-content-md-center row">
						<Col className="col" screen_size={6}>
							<p className="p-card-thick">Seleziona i tuoi posti cliccando sui posti disponibili.</p>
						</Col>
					</Row>
				</div>
			}else{
				return <div>
					<div className="divider"></div>
					{selectedSeats.map((seat) => (
						<Row className="justify-content-md-center row">
							<Col className="col" screen_size={6}>
								<div className='align-two-text-flex'>
									<p>{seat.category}</p>
									<i className="icons-table-users fa fa-trash text-danger cursor-pointer-text" onClick={(e) => deleteSeat(e, seat)}></i>
								</div>
								{renderSectorBySeat(mapData, seat)}
								<div className='align-two-text-flex'>
									<p className="title1-modal-event">Fila&nbsp;<p className="title2-modal-event">{seat.row_number}</p></p>
									<p className="title1-modal-event">Posto&nbsp;<p className="title2-modal-event">{seat.seat_number}</p></p>
								</div>
								{renderDropboxItems(seat)}
								<div className="divider"></div>
							</Col>
						</Row>
					))}
				</div>
			}
		}

	};

	const deleteSeat = (e, seat) => {
		e.preventDefault();
		e.stopPropagation();
		handleDeselect(seat)
	};

	const setItemToSeat = (e, arr, seat) => {
		let total = 0;
		if (typeof (arr) !== 'undefined' && arr !== null) {
			if (typeof (e.target.selectedIndex) !== 'undefined' && e.target.selectedIndex !== null) {
				const item = arr[e.target.selectedIndex];
				seat.typeTicket = item;
				for (var i=0; i < selectedSeats.length; i++) {
					let seat = selectedSeats[i];
					let presale = 0;
					if (typeof (seat.typeTicket.bundles) !== 'undefined' && seat.typeTicket.bundles !== null && seat.typeTicket.bundles.length > 0) {
						presale = seat.typeTicket.bundles[0].designated_price * 100;
					}
					const floatTotal = parseFloat(seat.typeTicket.default_price).toFixed(2);
					let v1 = total*100;
					let v2 = floatTotal*100;
					let totalV1V2 = (v1 + v2 + presale)/100;
					total = parseFloat(totalV1V2.toString()).toFixed(2);
				}
			}
		}
		setTotalAmount(total);
		e.preventDefault();
		e.stopPropagation();
	};

	const renderDropboxItems = (seat) => {
		if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription) {
			// in futuro ci potrà essere una nuova funzionalità
			return null;
		}else{
			if (mapData !== null){
				let arr = mapData['seatsItemsModelMap'];
				let arrTemp = arr[seat.category];
				let itemHashMap = arrTemp.itemHashMap;
				if (itemHashMap !== null && itemHashMap.length > 0){
					let dict = [];
					for (var i=0; i < itemHashMap.length; i++) {
						let obj = itemHashMap[i];
						if (typeof (obj.sales_channels) !== 'undefined' && obj.sales_channels !== null && obj.sales_channels.length > 0){
							const checkWeb = obj.sales_channels.every(item => item === Utils.KEY_WEB);
							if (checkWeb) {
								dict.push(obj);
							}
						}
					}
					if (dict.length > 0){
						return <Form.Select onChange={(e) => setItemToSeat(e, dict, seat)}>
							{dict.map((item) => (
								renderOption(seat, item)
							))}
						</Form.Select>
					}else{
						return null;
					}
				}else{
					return null;
				}
			}
		}
	};

	const renderOption = (seat, item) => {

		if (selectedSeats.length > 0){
			for (var i=0; i < selectedSeats.length; i++) {
				let objSeat = null;
				objSeat = selectedSeats[i];
				if (typeof (objSeat.typeTicket) !== 'undefined' && objSeat.typeTicket !== null) {
					if (typeof (objSeat.typeTicket.sales_channels) !== 'undefined' && objSeat.typeTicket.sales_channels !== null) {
						if(objSeat.typeTicket.sales_channels.length > 0){
							const checkWeb = objSeat.typeTicket.sales_channels.every(item => item === Utils.KEY_WEB);
							if (checkWeb) {
								if (seat.seat_guid === objSeat.seat_guid && item.default_price === objSeat.typeTicket.default_price){
									if (objSeat.typeTicket.quota_available_number === 0){
										if (typeof (objSeat.typeTicket.bundles) !== 'undefined' && objSeat.typeTicket.bundles !== null && objSeat.typeTicket.bundles.length > 0) {
											let bundleType = "";
											if (typeof (objSeat.typeTicket.bundles[0].bundle_type) !== 'undefined' &&  objSeat.typeTicket.bundles[0].bundle_type !== null) {
												bundleType = objSeat.typeTicket.bundles[0].bundle_type + ":";
												return <option selected disabled>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€ / {bundleType} {objSeat.typeTicket.bundles[0].designated_price}€</option>
											}else{
												return <option selected disabled>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€</option>
											}
										}else{
											return <option selected disabled>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€</option>
										}
									}else{
										if (typeof (objSeat.typeTicket.bundles) !== 'undefined' && objSeat.typeTicket.bundles !== null && objSeat.typeTicket.bundles.length > 0) {
											let bundleType = "";
											if (typeof (objSeat.typeTicket.bundles[0].bundle_type) !== 'undefined' &&  objSeat.typeTicket.bundles[0].bundle_type !== null) {
												bundleType = objSeat.typeTicket.bundles[0].bundle_type + ":";
												return <option selected>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€ / {bundleType} {objSeat.typeTicket.bundles[0].designated_price}€</option>
											}else{
												return <option selected>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€</option>
											}
										}else{
											return <option selected>{Functions.nameProduct(item.name.it)}: {objSeat.typeTicket.default_price}€</option>
										}
									}
								}
							}
						}
					}
				}
			}
		}

		if (typeof (item.sales_channels) !== 'undefined' && item.sales_channels !== null) {
			if(item.sales_channels.length > 0){
				const checkWeb = item.sales_channels.every(item => item === Utils.KEY_WEB);
				if (checkWeb) {
					if (item.quota_available_number === 0){
						if (typeof (item.bundles) !== 'undefined' && item.bundles !== null && item.bundles.length > 0) {
							let bundleType = "";
							if (typeof (item.bundles[0].bundle_type) !== 'undefined' &&  item.bundles[0].bundle_type !== null) {
								bundleType = item.bundles[0].bundle_type + ":";
								return <option disabled>{Functions.nameProduct(item.name.it)}:  {item.default_price}€ / {bundleType} {item.bundles[0].designated_price}€</option>
							}else{
								return <option disabled>{Functions.nameProduct(item.name.it)}: {item.default_price}€</option>
							}
						}else{
							return <option disabled>{Functions.nameProduct(item.name.it)}: {item.default_price}€</option>
						}
					}else{
						if (typeof (item.bundles) !== 'undefined' && item.bundles !== null && item.bundles.length > 0) {
							let bundleType = "";
							if (typeof (item.bundles[0].bundle_type) !== 'undefined' &&  item.bundles[0].bundle_type !== null) {
								bundleType = item.bundles[0].bundle_type + ":";
								return <option>{Functions.nameProduct(item.name.it)}: {item.default_price}€ / {bundleType} {item.bundles[0].designated_price}€</option>
							}else{
								return <option>{Functions.nameProduct(item.name.it)}: {item.default_price}€</option>
							}
						}else{
							return <option>{Functions.nameProduct(item.name.it)}: {item.default_price}€</option>
						}
					}
				}
			}
		}

	};

	const checkAvailableMap = () => {
		if (props.values.typeTicket === Utils.TicketModularSubscription || props.values.typeTicket === Utils.TicketFixedSubscription){
			return <div>
				{mapData?.pretixResponseModel?.available_seats?.length < 1 ? (
					<div className="overlay">
						<div className="overlay-wrapper">
							<div className="overlay-text">Posti non disponibili</div>
						</div>
					</div>
				) : null}
			</div>
		}else{
			if (mapData !== null){
				let arr = mapData["seatsItemsModelMap"];
				let dict = [];
				for (var i=0; i < Object.keys(arr).length; i++) {
					let key = Object.keys(arr)[i];
					let arrTemp = arr[key];
					let seatList = arrTemp['seatList'];
					if (seatList.length > 0){
						for (var j=0; j < seatList.length; j++) {
							dict.push(seatList[j])
						}
					}
				}
				return <div>
					{dict.length < 1 ? (
						<div className="overlay">
							<div className="overlay-wrapper">
								<div className="overlay-text">Posti non disponibili</div>
							</div>
						</div>
					) : null}
				</div>
			}else{
				return null;
			}
		}
	};


	const iWidth = width ? width : window.innerWidth;
	const [showSlidebar, setShowSlidebar] = useState(false);
	const handleCloseSlidebar = () => setShowSlidebar(false);
	const handleShowSlidebar = () => setShowSlidebar(true);
	const [showSlidebarEvents, setShowSlidebarEvents] = useState(false);
	const handleCloseSlidebarEvents = () => setShowSlidebarEvents(false);
	const handleShowSlidebarEvents = () => setShowSlidebarEvents(true);

	const [presenseDropdown, setPresenseDropdown] = useState(false);
	const presenseDropdownRef = useRef();
	useOutsideClick(presenseDropdownRef, () => { setPresenseDropdown(false) })
	const [selectedFilter, setFilter] = useState("All")

	if (msgError) {
		return <div>Errore: {msgError}</div>;
	} else if (!isLoaded) {
		return <div><Spinner animation="border" role="status" variant="primary" >
			<span className="visually-hidden">Caricamento...</span>
		</Spinner></div>;
	}else{
		return (
			<>
				<>
					<div>
						<ToastContainer
							position="bottom-center"
							autoClose={3000}
							hideProgressBar={false}
							closeOnClick
							newestOnTop={true}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							style={{ "zIndex":1000000}}
						>
						</ToastContainer>
					</div>
				</>
				<fieldset style={{ position: "relative" }}>
					<Modal
						{...props}
						size="xs"
						show={showModal}
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<Modal.Body>{msgModal}</Modal.Body>
						<Spinner className="center-spinner-loader" animation="border" role="status" variant="primary" >
							<span className="visually-hidden">Caricamento...</span>
						</Spinner>
					</Modal>
					<Offcanvas show={showSlidebar} onHide={handleCloseSlidebar} {...props} placement='end' name='end' className="overlay-event-menu">
						<Offcanvas.Header closeButton>
							<Offcanvas.Title>Posti selezionati</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							{renderSeatsInSlidebar(mapData)}
						</Offcanvas.Body>
					</Offcanvas>
					{
						typeof(props.values.itemsEventSelected) !== 'undefined' && props.values.itemsEventSelected !== null
							?(
								<Offcanvas show={showSlidebarEvents} onHide={handleCloseSlidebarEvents} {...props} placement='end' name='end' className="overlay-event-menu">
									<Offcanvas.Header closeButton>
										<Offcanvas.Title>Spettacoli selezionati</Offcanvas.Title>
									</Offcanvas.Header>
									<Offcanvas.Body>
										{props.values.itemsEventSelected.map((item) => (
											<Row className="justify-content-md-center row">
												<Col className="col" screen_size={6}>
													<p className="p-card-thick">{item.name.it}</p>
													<img src={item.imgEvent} className="img-event-sidebar" alt="immagine dell'evento" />
													<p className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;
														{Functions.convertDate(item.date_from)}
													</p>
													<p className="title1-modal-event">Tipologia:&nbsp;</p>
													<p className="title2-modal-event">{item.tipology}</p>
													<div className="divider"></div>
												</Col>
											</Row>
										))}
									</Offcanvas.Body>
								</Offcanvas>
							)
							: null
					}
					{/* <input type={"file"} onChange={onFileUpload} /> */}

					{checkAvailableMap()}

					<div style={{ position: 'relative' }} ref={presenseDropdownRef}>
						<button className="btn btn-primary shadow zoom-button filter-button" onClick={(e) => {
							e.preventDefault();
							setPresenseDropdown(!presenseDropdown);
						}}>
							<i className="fa fa-filter" />
						</button>
						<div className={`map-filters dropdown ${presenseDropdown ? 'dropdown--visible' : ''}`}>
							<FilterDropdown
								findColorByCategory={findColorByCategory}
								zones={mapData?.mappa.layout.zones}
								setFilter={setFilter}
								selectedZone={selectedFilter}
							/>
						</div>
					</div>
					<div className="form-card">
						<div className='align-two-text-flex'>
							<h2 className="fs-title">Seleziona il posto/i desiderati</h2>
							<h3 className="fs-title-secondary-18 text-color-secondary mt-7">Totale: {totalAmount} €</h3>
						</div>
						<div className='align-two-text-flex'>
							<Badge bg="info" className="fs-title-secondary-14 mt-7 cursor-pointer-text mb-10" onClick={handleShowSlidebar}>
								<i className="fa fa-chair"></i>&nbsp;
								Posti selezionati: {totalSeat}
							</Badge>
							{
								typeof(props.values.itemsEventSelected) !== 'undefined' && props.values.itemsEventSelected !== null
									?(
										<div>
											<img src={theaterEvent} alt="Spettacoli selezionati" height="20px" onClick={handleShowSlidebarEvents} className="fs-subtitle-right img-num-event-selected" />
											<Badge bg="secondary" className="badge-event-selected">{props.values.itemsEventSelected.length}</Badge>
										</div>
									)
									: null
							}
						</div>
						<TransformWrapper
							//centerZoomedOut={true}
							centerOnInit={true}
							maxPositionX={window.innerWidth}
							maxPositionY={window.innerHeight - 200}
							wrapperClass={"zoom-wrapper"}
							contentClass={"zoom-wrapper"}
							// limitToBounds={false}
							initialScale={(iWidth < 1080 && iWidth > 860) ? 0.8 : (iWidth < 860 && iWidth > 500) ? 0.5 : (iWidth < 500) ? 0.35 : undefined}
							minScale={-10}
						>
							{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
								<React.Fragment>
									<TransformComponent wrapperStyle={{ margin: '0 auto', backgroundColor: 'rgba(23, 28, 56, .7)' }}>
										<SeatMap
											{...props}
											mapData={mapData}
											handleHover={handleHover}
											checkAvailability={checkAvailability}
											checkIsSelected={checkIsSelected}
											handleSelect={handleSelect}
											handleDeselect={handleDeselect}
											selectedSeats={selectedSeats}
											findColorByCategory={findColorByCategory}
											selectedFilter={selectedFilter}
										/>
									</TransformComponent>
									<div className="zoom-seat-map tools">
										<div
											className="btn-area"
											onClick={(e) => {
												e.preventDefault();
												zoomIn();
											}}
										>
											<button className="btn btn-primary shadow zoom-button">
												<i className="fa fa-plus" />
											</button>
										</div>
										<div
											className="btn-area"
											onClick={(e) => {
												e.preventDefault();
												zoomOut();
											}}
										>
											<button className="btn btn-primary shadow zoom-button">
												<i className="fa fa-minus" />
											</button>
										</div>
										<div
											className="btn-area"
											onClick={(e) => {
												e.preventDefault();
												resetTransform();
											}}
										>
											<button className="btn btn-primary shadow zoom-button">
												<i className="fa fa-times" />
											</button>
										</div>
									</div>
								</React.Fragment>
							)}
						</TransformWrapper>
						{/* draw popup as html */}
						{popup?.seat && (
							<SeatPopup
								position={popup?.position}
								seat={popup?.seat}
								isBooked={!checkAvailability(popup?.seat, mapData)}
								isSelected={checkIsSelected(popup?.seat, selectedSeats)}
								onClose={() => {
									setPopup({ seat: null });
								}}
							/>
						)}

					</div>
				</fieldset>
			</>
		);
	}


}

export default ZoomContent;
