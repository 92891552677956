import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import Seo from "../elements/seo";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import Utils from "../Utils";

//ContactUs, contact page
class ContactUs extends Component{

	constructor() {
		super();

		this.state = {
			isvalid: false,
			message: "",
			nameUser: "",
			emailUser: "",
			messageUser: ""
		};
	}

	sendEmailSupport = () =>{

		if (this.state.nameUser === "" || this.state.emailUser === "" || this.state.messageUser === ""){
			toast.error("Devi compilare tutti i campi", {
				theme: "colored",
				position: "bottom-center",
				autoClose: 3000
			});
		}else{
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailUser))
			{
				let params = {'mittente': this.state.nameUser, 'emailMittente': this.state.emailUser, 'messaggio': this.state.messageUser};
				const paramsString = JSON.stringify(params);

				let headers = {
					method: 'POST',
					body: paramsString,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
						'Host': Utils.BASE_URL_SERVER_CUSTOM
					}
				};

				let link = Utils.URL_API_SERVER_CUSTOM_INVIA_EMAIL_SUPPORTO;

				fetch(link, headers)
					.then((response) => response)
					.then((responseJson) => {
						if (responseJson.status === 200){
							this.setState(
								{
									isvalid: false,
									message: "",
									nameUser: "",
									emailUser: "",
									messageUser: ""
								}
							);
							toast.success("Email inviata con successo", {
								theme: "colored",
								position: "bottom-center",
								autoClose: 3000
							});
						}else{
							toast.error("Email non inviata", {
								theme: "colored",
								position: "bottom-center",
								autoClose: 3000
							});
						}
					})
					.catch((error) => {
						toast.error("Email non inviata", {
							theme: "colored",
							position: "bottom-center",
							autoClose: 3000
						});
					});
			}else{
				toast.error("Email non valida", {
					theme: "colored",
					position: "bottom-center",
					autoClose: 3000
				});
			}
		}

	}

	handleChange = (event, id) => {
		event.preventDefault();
		if (id === "nameUser"){
			const {value} = event.target;
			this.setState({
				nameUser : value,
			})
		}
		if (id === "emailUser"){
			const {value} = event.target;
			this.setState({
				emailUser : value,
			})
		}
		if (id === "messageUser"){
			const {value} = event.target;
			this.setState({
				messageUser : value,
			})
		}
	};

	render(){
		const {nameUser} = this.state;
		const {emailUser} = this.state;
		const {messageUser} = this.state;

		let dict = {};
		dict["title"] = Utils.NAME_CUSTOMER + " | Contatti";
		dict["description"] = Utils.NAME_CUSTOMER + " | Contatti";

		return (
			<>
				<>
					<div>
						<ToastContainer
							position="bottom-center"
							autoClose={3000}
							hideProgressBar={false}
							closeOnClick
							newestOnTop={true}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							style={{ "zIndex":1000000}}
						>
						</ToastContainer>
					</div>
				</>
				<Seo propsSeo={dict}></Seo>
				<Header />

				<div className="page-content bg-white">

					<div className="banner-wraper">
						<div className="page-banner banner-lg contact-banner">
							<div className="container">
								<div className="page-banner-entry text-center">
									<h2>Contatti</h2>
								</div>
							</div>
						</div>
					</div>

					<section>
						<div className="container">
							<div className="contact-wraper">
								<div className="row">
									<div className="col-lg-6 mb-30">
										<div className="row">
											<div className="row">
												<div className="col-md-12">
													<input name="name" type="text" required
														   id="nameUser"
														   value={nameUser} onChange={(e) => this.handleChange(e,"nameUser")}
														   className="form-control" placeholder="Nome"/>
												</div>
												<div className="col-md-12">
													<input name="email" type="email" required
														   id="emailUser"
														   onChange={(e) => this.handleChange(e,"emailUser")}
														   value={emailUser}
														   className="form-control" placeholder="Email"/>
												</div>
												<div className="col-md-12">
														<textarea name="message" required
																  id="messageUser"
																  onChange={(e) => this.handleChange(e,"messageUser")}
																  value={messageUser}
																  className="form-control" placeholder="Messaggio"></textarea>
												</div>
												<div className="col-lg-12">
													<Button onClick={this.sendEmailSupport} variant="secondary" size="lg">Invia</Button>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 mb-30">
										<div className="contact-info ovpr-dark">
											<div className="info-inner">
												<h4 className="title mb-30">Sede</h4>
												<p><i className="ti-map-alt"></i> Viale Remo De Feo, 1, 80022 Arzano NA</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature4">
										<div className="icon-md feature-icon">
											<img src={icon1} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Per info</h5>
											<p>081 1857 6008</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature2">
										<div className="icon-md feature-icon">
											<img src={icon2} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Orari Ufficio</h5>
											<p>Dal lunedì al venerdì h 09:00-18:00</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature3">
										<div className="icon-md feature-icon">
											<img src={icon3} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Email</h5>
											<strong className="text-color-secondary">SUPPORTO</strong>
											<p><a href="mailto:support@ticalo.it">support@ticalo.it</a></p>
											<br></br>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

				</div>

				<Footer />

			</>
		);
	}
}

export default ContactUs;
