import React, {Component} from "react";
import {Link} from "react-router-dom";
import Utils from "../../Utils";
import Messages from "../../Messages";
import {Card} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Badge from "react-bootstrap/Badge";
import firebase from '../../firebase';
import Functions from "../../Functions";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

// Import Images
import cardCredit from "../../../images/icon/payment-credit-card.png";
import shieldSuccess from "../../../images/shield.png";

export default class PaymentInfo extends Component {

    constructor() {
        super();
        this.emailRef = React.createRef();
        this.nameRef = React.createRef();
        this.surnameRef = React.createRef();

        this.state = {
            totalAmount: 0,
            totalAmountApprox: "",
            phoneUser: "",
            emailUser: "",
            nameUser: "",
            surnameUser: "",
            showModalEmail: false,
            showModalEmailAndData: false,
            showErrorEmail: false,
            showErrorData: false,
            otpUser: "",
            method: "",
            visualizeTryAgain: true,
            validatedPhone: false,
            validatedOTP: true,
            verifiedPhone: false,
            verifiedOTP: false,
            visualizeOTP: true,
            visualizePayment: true,
            showModalPayment: false,
            visualizeBadgePayment: true,
            showLinkStripe: true,
            linkStripe: "",
            event: null,
            requireEmail:  false,
            requireNameAndSurname:  false
        };
    }

    componentDidMount() {

        if (typeof(this.props.values.arrUsers) !== 'undefined' && this.props.values.arrUsers !== null){
            if (this.props.values.arrUsers.length > 0){
                this.setState(
                    {
                        emailUser: this.props.values.arrUsers[0].email
                    }
                );
            }
        }
        if (typeof(this.props.values.event) !== 'undefined' && this.props.values.event !== null){
            this.setState(
                {
                    event: this.props.values.event,
                    requireEmail: this.props.values.event.jsonSettings.attendee_emails_asked,
                    requireNameAndSurname: this.props.values.event.jsonSettings.attendee_names_asked
                }
            );
        }

        this.setState(
            {
                totalAmount: this.props.values.totalAmount
            }
        );

        this.configureCaptcha();

    }

    closeModalEmail = (e, method) => {
        e.preventDefault();
        this.setState(
            {
                showModalEmail: false
            }
        );
    };

    closeModalEmailAndData = () => {
        this.setState(
            {
                showModalEmailAndData: false
            }
        );
    };

    back = e => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.props.prevStep();
    };

    paymentAmount = (e, method) => {
        e.preventDefault();

        if(this.props.values.countDownFinish !== false){

            if (this.state.emailUser !== ""){
                this.setState(
                    {
                        showLinkStripe: true,
                        method: method
                    }
                );

                let params = {};
                let link = '';

                if (this.props.values.typeTicket === Utils.TicketModularSubscription || this.props.values.typeTicket === Utils.TicketFixedSubscription){
                    let numRate = 1;
                    if (method === "rate"){
                        numRate = Utils.NUM_RATE;
                    }
                    link = Utils.URL_API_SERVER_CUSTOM_POST_PRENOTAZIONE_ABBONAMENTO;

                    let arrCartPositions = [];
                    for (let i=0; i < this.props.values.arrCartPositions.length; i++) {
                        let arr = this.props.values.arrCartPositions[i];
                        if (arr.length > 0){
                            for (var j=0; j < arr.length; j++) {
                                arrCartPositions.push(arr[j]);
                            }
                        }
                    }

                    params = {'bigliettoConMappaModelList': arrCartPositions,
                        'organizerSlug': this.props.values.organizer.slug,
                        'mainPhone': this.state.phoneUser,
                        'postazioneEmissione': "000001",
                        'salesChannel': "web",
                        'rateNumber': numRate,
                        'codiceRichiedente': "CW",
                        'stripe': true};

                }else{

                    let arrTickets = [];
                    if (this.state.event !== null){
                        if (!this.state.requireEmail && !this.state.requireNameAndSurname){
                            params = {
                                'eventSlug': this.props.values.event.slug,
                                'mainCf': "",
                                'mainEmail': this.state.emailUser,
                                "mainLuogoNascita": "",
                                "mainDataNascita": "",
                                'mainNomeCognome': this.state.nameUser + " " + this.state.surnameUser,
                                'mainPhone': this.state.phoneUser,
                                'organizerSlug': this.props.values.organizer.slug,
                                'postazioneEmissione': "000001",
                                'rateNumber': 1,
                                'salesChannel': "web",
                                'codiceRichiedente': "CW",
                                'stripe': true};
                        }else{
                            params = {
                                'eventSlug': this.props.values.event.slug,
                                'mainCf': this.props.values.arrUsers[0].cf,
                                'mainEmail': this.state.emailUser,
                                "mainLuogoNascita": this.props.values.arrUsers[0].placeOfBirth,
                                "mainDataNascita": this.props.values.arrUsers[0].dateOfBirth,
                                'mainNomeCognome': this.props.values.arrUsers[0].name + " " + this.props.values.arrUsers[0].surname,
                                'mainPhone': this.state.phoneUser,
                                'organizerSlug': this.props.values.organizer.slug,
                                'postazioneEmissione': "000001",
                                'rateNumber': 1,
                                'salesChannel': "web",
                                'codiceRichiedente': "CW",
                                'stripe': true};
                        }
                    }else{
                        params = {
                            'eventSlug': this.props.values.event.slug,
                            'mainCf': this.props.values.arrUsers[0].cf,
                            'mainEmail': this.state.emailUser,
                            "mainLuogoNascita": this.props.values.arrUsers[0].placeOfBirth,
                            "mainDataNascita": this.props.values.arrUsers[0].dateOfBirth,
                            'mainNomeCognome': this.props.values.arrUsers[0].name + " " + this.props.values.arrUsers[0].surname,
                            'mainPhone': this.state.phoneUser,
                            'organizerSlug': this.props.values.organizer.slug,
                            'postazioneEmissione': "000001",
                            'rateNumber': 1,
                            'salesChannel': "web",
                            'codiceRichiedente': "CW",
                            'stripe': true};
                    }

                    link = Utils.URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_SENZA_MAPPA;

                    if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                            link = Utils.URL_API_SERVER_CUSTOM_POST_BIGLIETTI_SINGOLI_CON_MAPPA;
                            for (let x=0; x < this.props.values.arrCartPositions.length; x++) {
                                let objTicket = {};
                                objTicket['cf'] = this.props.values.arrUsers[x].cf;
                                objTicket['email'] = this.props.values.arrUsers[x].email;
                                objTicket['nome'] = this.props.values.arrUsers[x].name;
                                objTicket['cognome'] = this.props.values.arrUsers[x].surname;
                                objTicket['phone'] = this.props.values.arrUsers[x].phone;
                                objTicket['categoria'] = this.props.values.arrUsers[x].nameSector;
                                objTicket['eventoSlug'] = this.props.values.event.slug;
                                objTicket['seatGuid'] = this.props.values.arrCartPositions[x].seat_guid;
                                objTicket['cartId'] = this.props.values.arrCartPositions[x].cartPositions[0].cartId;
                                objTicket['id'] = this.props.values.arrCartPositions[x].cartPositions[0].id;
                                objTicket['idItem'] = this.props.values.arrCartPositions[x].typeTicket.id;
                                objTicket['dataNascita'] = this.props.values.arrUsers[x].dateOfBirth;
                                objTicket['luogoNascita'] = this.props.values.arrUsers[x].placeOfBirth;
                                objTicket["idBigliettoTabella"] = this.props.values.arrUsers[x].idBigliettoTabella;
                                arrTickets.push(objTicket);
                            }
                            params['bigliettoConMappaModelList'] = arrTickets;

                    }else{
                        for (let z=0; z < this.props.values.arrUsers.length; z++) {
                            let objTicket = {};
                            objTicket['cf'] = this.props.values.arrUsers[z].cf;
                            objTicket['email'] = this.props.values.arrUsers[z].email;
                            objTicket['nome'] = this.props.values.arrUsers[z].name;
                            objTicket['cognome'] = this.props.values.arrUsers[z].surname;
                            objTicket['phone'] = this.props.values.arrUsers[z].phone;
                            objTicket['categoria'] = this.props.values.arrUsers[z].nameSector;
                            objTicket['idItem'] = this.props.values.arrUsers[z].idTicket;
                            objTicket['dataNascita'] = this.props.values.arrUsers[z].dateOfBirth;
                            objTicket['luogoNascita'] = this.props.values.arrUsers[z].placeOfBirth;
                            objTicket["idBigliettoTabella"] = this.props.values.arrUsers[z].idBigliettoTabella;
                            arrTickets.push(objTicket);
                        }
                        params['bigliettoModelList'] = arrTickets;
                    }
                }

                const paramsString = JSON.stringify(params);

                let headers = {
                    method: 'POST',
                    body: paramsString,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
                        'Host': Utils.BASE_URL_SERVER_CUSTOM
                    }
                };

                this.setState(
                    {
                        showModalPayment: true
                    }
                );

                fetch(link, headers)
                    .then((response) => {
                        if(!response.ok) throw response.status;
                        else return response.json();
                    })
                    .then((responseJson) => {
                        if (responseJson.stripeLink !== ""){
                            this.setState(
                                {
                                    showModalPayment: false
                                }
                            );
                            let stripeLink = responseJson.stripeLink;
                            window.open(`${stripeLink}`, "_self");

                        }else{
                            this.setState(
                                {
                                    showModalPayment: false
                                }
                            );
                            Functions.alertMsgError(Messages.MsgErrorLinkPayment);
                        }
                    })
                    .catch((error) => {
                        this.setState(
                            {
                                showModalPayment: false
                            }
                        );
                        Functions.alertMsgError(Messages.MsgErrorServer);

                    });
            }else{
                if (this.state.event !== null){
                    if (!this.state.requireEmail && !this.state.requireNameAndSurname){
                        this.setState(
                            {
                                showModalEmailAndData: true
                            }
                        );
                    }else{
                        this.setState(
                            {
                                showModalEmail: true
                            }
                        );
                    }
                }else{
                    this.setState(
                        {
                            showModalEmail: true
                        }
                    );
                }
            }
        }else{
            Functions.alertMsgError(Messages.MsgTimerExpired);
        }

    };

    handlePaste = event => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    handleChangePhone = (event) => {
        event.preventDefault();
        const {value} = event.target;
        if (!this.state.validatedPhone){
            this.setState({
                phoneUser : value,
            })
        }

    };

    handleChangeOtp = (event) => {
        event.preventDefault();
        const {value} = event.target;
        if (!this.state.validatedOTP){
            this.setState({
                otpUser : value,
            })
        }
    };

    configureCaptcha = () =>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("Recaptca validato")
            },
            defaultCountry: "IT"
        });
    };

    onSignInSubmit = () => {

        if(this.props.values.countDownFinish !== false){
            this.setState(
                {
                    verifiedPhone: true
                }
            );
            const phoneNumber = this.state.phoneUser;
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    this.setState(
                        {
                            verifiedPhone: false
                        }
                    );
                    this.setState(
                        {
                            validatedPhone: true
                        }
                    );
                    this.setState(
                        {
                            validatedOTP: false
                        }
                    );
                    this.setState(
                        {
                            visualizeOTP: false
                        }
                    );
                }).catch((error) => {
                this.setState(
                    {
                        visualizeOTP: true,
                        verifiedOTP: false,
                        verifiedPhone: false,
                        validatedPhone: false
                    }
                );
                Functions.alertMsgError(Messages.MsgUnverifiedPhoneNumber);
            });
        }else{
            Functions.alertMsgError(Messages.MsgTimerExpired);
        }

    };

    onSubmitOTP = () =>{

        if(this.props.values.countDownFinish !== false){
            this.setState(
                {
                    verifiedOTP: true
                }
            );
            const code = this.state.otpUser;
            window.confirmationResult.confirm(code).then((result) => {
                //const user = result.user;
                this.setState(
                    {
                        validatedPhone: true
                    }
                );
                this.setState(
                    {
                        verifiedOTP: false
                    }
                );
                this.setState(
                    {
                        validatedOTP: true
                    }
                );
                this.setState(
                    {
                        visualizePayment: false
                    }
                );
            }).catch((error) => {
                this.setState(
                    {
                        visualizeOTP: true,
                        verifiedOTP: false,
                        validatedPhone: false
                    }
                );
                Functions.alertMsgError(Messages.MsgUnverifiedOTP);
            });
        }else{
            Functions.alertMsgError(Messages.MsgTimerExpired);
        }
    };

    renderButtonVerifiedPhone() {
        if (this.state.verifiedPhone) {
            return (
                <Spinner animation="border" role="status" variant="primary" >
                    <span className="visually-hidden">Caricamento...</span>
                </Spinner>
            );
        }else{
            return(
                <div>
                    <Button onClick={this.onSignInSubmit} variant="secondary" size="sm" hidden={this.state.validatedPhone}>Verifica</Button>
                </div>
            );
        }
    }

    renderButtonVerifiedOTP() {
        if (this.state.verifiedOTP) {
            return (
                <Spinner animation="border" role="status" variant="primary" >
                    <span className="visually-hidden">Caricamento...</span>
                </Spinner>
            );
        }else{
            return( <Button onClick={this.onSubmitOTP} variant="secondary" size="sm" hidden={this.state.validatedOTP}>Verifica</Button>);
        }
    }

    confirmEmail = () => {

        const validEmail = this.refs.emailRef.value;

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(validEmail))
        {
            this.setState({
                showErrorEmail : false,
                showModalEmail: false,
                emailUser : this.refs.emailRef.value
            })
        }else{
            this.setState({
                showErrorEmail : true
            })
        }

    };

    confirmData = () => {

        const validEmail = this.refs.emailRef.value;

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(validEmail))
        {
            if(this.refs.nameRef.value !== "" && this.refs.surnameRef.value !== ""){
                if (this.props.values.arrUsers.length > 0){
                    if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                        for (var i=0; i < this.props.values.arrCartPositions.length; i++) {
                            this.props.values.arrUsers[i].email = null;
                            this.props.values.arrUsers[i].name = null;
                            this.props.values.arrUsers[i].surname = null;
                            this.props.values.arrUsers[i].phone = null;
                            this.props.values.arrUsers[i].dateOfBirth = null;
                            this.props.values.arrUsers[i].placeOfBirth = null;
                            this.props.values.arrUsers[i].idBigliettoTabella = null;
                        }
                    }else{
                        for (var j=0; j < this.props.values.arrUsers.length; j++) {
                            this.props.values.arrUsers[j].email = null;
                            this.props.values.arrUsers[j].name = null;
                            this.props.values.arrUsers[j].surname = null;
                            this.props.values.arrUsers[j].phone = null;
                            this.props.values.arrUsers[j].dateOfBirth = null;
                            this.props.values.arrUsers[j].placeOfBirth = null;
                            this.props.values.arrUsers[j].idBigliettoTabella = null;
                        }
                    }
                }
                this.setState({
                    showErrorEmail : false,
                    showErrorData : false,
                    showModalEmailAndData: false,
                    visualizeBadgePayment: false,
                    emailUser : this.refs.emailRef.value,
                    nameUser : this.refs.nameRef.value,
                    surnameUser : this.refs.surnameRef.value
                })
            }else{
                this.setState({
                    showErrorData : true
                })
            }
        }else{
            this.setState({
                showErrorEmail: true,
                showErrorData: false
            })
        }

    };

    render() {
        const {phoneUser} = this.state;
        const {otpUser} = this.state;

        return (
            <>
                <>
                    <div>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            closeOnClick
                            newestOnTop={true}
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{ "zIndex":1000000}}
                        >
                        </ToastContainer>
                    </div>
                </>
                <fieldset>
                    <Modal
                        size="lg"
                        show={this.state.showModalPayment}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>Stiamo elaborando il tuo ordine, sarai reindirizzato alla pagina di pagamento...</Modal.Body>
                        <Spinner className="center-spinner-loader" animation="border" role="status" variant="primary" >
                            <span className="visually-hidden">Caricamento...</span>
                        </Spinner>
                    </Modal>
                    <Modal size="lg" show={this.state.showModalEmail}
                           aria-labelledby="contained-modal-title-vcenter"
                           centered
                           style={{"zIndex": "10000000", "overflowY": "auto"}}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Inserisci un'email
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div>
                                <input type="email"
                                       name="email"
                                       id="email" ref="emailRef"></input>&nbsp;
                                <Button className="btn btn-primary confirm-button" onClick={this.confirmEmail}>
                                    Conferma
                                </Button>
                                <div hidden={!this.state.showErrorEmail}><p className="text-danger title-notice">{Messages.MsgErrorValidMail}</p></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" show={this.state.showModalEmailAndData}
                           aria-labelledby="contained-modal-title-vcenter"
                           centered
                           style={{"zIndex": "10000000", "overflowY": "auto"}} onHide={this.closeModalEmailAndData}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Inserisci dati
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div>
                                <input type="email"
                                       name="email"
                                       placeholder="Email"
                                       id="email" ref="emailRef" />
                            </div>&nbsp;
                            <div>
                            <input type="name"
                                   name="name"
                                   placeholder="Nome"
                                   id="name" ref="nameRef" />
                            </div>&nbsp;
                            <div>
                            <input type="surname"
                                   name="surname"
                                   placeholder="Cognome"
                                   id="surname" ref="surnameRef" />
                            </div>&nbsp;
                            <div>
                            <Button className="btn btn-primary confirm-button" onClick={this.confirmData}>
                                    Conferma
                                </Button>
                                <div hidden={!this.state.showErrorData}><p className="text-danger title-notice">{Messages.MsgCompileAllData}</p></div>
                                <div hidden={!this.state.showErrorEmail}><p className="text-danger title-notice">{Messages.MsgErrorValidMail}</p></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="form-card">
                        <h2 className="fs-title">Indicazioni di pagamento</h2>
                        <div validated={this.state.validatedPhone}>
                            <div id="sign-in-button"></div>
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <p>Inserisci un numero di cellulare per la verifica</p>
                                    <PhoneInput
                                        name="phone"
                                        required
                                        international
                                        id="phoneuser"
                                        onChange={phone => this.setState(
                                            {
                                                phoneUser: phone
                                            }
                                        )}
                                        defaultCountry="IT"
                                        placeholder="Numero di cellulare"
                                        value={phoneUser}
                                        onPaste={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}/>
                                </Col>
                                <Col xs="auto" className="my-1">
                                    {this.renderButtonVerifiedPhone()}
                                </Col>
                            </Row>
                        </div>
                        <div hidden={this.state.visualizeOTP} validated={this.state.validatedOTP.toString()}>
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <p>Inserisci OTP</p>
                                    <input
                                        type="text"
                                        name="phoneotp"
                                        required
                                        id="phoneotp" onChange={this.handleChangeOtp}
                                        placeholder="Codice OTP"
                                        value={otpUser}
                                        onPaste={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                    />
                                </Col>
                                <Col xs="auto" className="my-1">
                                    {this.renderButtonVerifiedOTP()}
                                </Col>
                            </Row>
                        </div>
                        <div className='align-two-card-flex' hidden={this.state.visualizePayment}>
                            <div hidden={this.state.visualizeBadgePayment} className='badge-success-payment'><Badge bg="success"
                            ><img src={shieldSuccess} alt="" width="25"/></Badge></div>
                            <Link to="" onClick={(e) => this.paymentAmount(e, "intero")}>
                                <Card
                                    bg='primary'
                                    key='primary'
                                    text='white'
                                    style={{ width: '18rem', height: '250px'}}
                                    className="mb-4"
                                >
                                    <Card.Body>
                                        <Card.Title>Pagamento diretto</Card.Title>
                                        <Card.Text>
                                            {this.state.totalAmount} €
                                        </Card.Text>
                                        <img src={cardCredit} alt="" className="rounded mx-auto d-block" width="150"/>
                                    </Card.Body>

                                </Card>
                            </Link>
                        </div>
                    </div>
                </fieldset>
            </>
        );
    }
}
