import React from "react";
import Moment from "react-moment";
import {Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DataManager from "../../../DataManager";
import {toast, ToastContainer} from "react-toastify";
import {withRouter} from 'react-router-dom';

// Images
import iconSoldOut from '../../../../images/soldout.png';
import imgPlaceholder from "../../../../images/placeholderTicalo.png";

//components
import Utils from "../../../Utils";
import Spinner from "react-bootstrap/Spinner";
import Functions from "../../../Functions";
import Messages from "../../../Messages";

class Event extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            totItems: 0,
            resultDataItems: [],
            resultDataQuotas: [],
            resultDataQuotasAllEvents: [],
            resultDataAvailabilityAllEvents: [],
            statusTicketSelected: true,
            showModalInfoEvent: false,
            arrEvents: this.props.arrEvents,
            event: this.props.event,
            eventSelected: this.props.event,
            organizer: this.props.organizer,
            isLoadedDates: false,
            msgErrorDates: ""
        };

    }

    dateConverter = (startDate, timeEnd) => {
        const newStartDate= new Date(startDate);
        const newEndDate=new Date(timeEnd);
        const one_day = 1000*60*60*24;
        let result
        result = Math.ceil((newEndDate.getTime()-newStartDate.getTime())/(one_day))
        console.log('date Converter result', result)
        if (result < 0 ) {return 0}
        return result
    }

    componentDidMount() {
        if (this.props.arrEvents.length > 0){
            let arrSlug = [];
            //const date = Functions.convertDateByTimezone(new Date(new Date()).toISOString());
            const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

            for (var i = 0; i < this.state.arrEvents.length; i++) {

                const dateFrom = moment(this.state.arrEvents[i].date_from).utc().format('YYYY-MM-DD HH:mm:ss')
                const starts = moment(date);
                const ends   = moment(dateFrom);
                const durationMillisecondsStart = moment.duration(ends.diff(starts));

                let presaleStart = this.state.arrEvents[i].presale_start;
                let presaleEnd = this.state.arrEvents[i].presale_end;

                if (typeof (dateFrom) !== 'undefined' && dateFrom !== null) {
                    if (durationMillisecondsStart._milliseconds > 0) {
                        if (typeof (presaleStart) !== 'undefined' && presaleStart !== null && typeof (presaleEnd) !== 'undefined' && presaleEnd !== null) {
                            //const start = Functions.convertDate(this.state.arrEvents[i].presale_start.toString());
                            //const end = Functions.convertDate(this.state.arrEvents[i].presale_end.toString());
                            const startPresale = moment(this.state.arrEvents[i].presale_start).utc().format('YYYY-MM-DD HH:mm:ss')

                            const momentStartPresale   = moment(startPresale);
                            const durationMillisecondsStartPresale = moment.duration(momentStartPresale.diff(starts));

                            const endPresale = moment(this.state.arrEvents[i].presale_end).utc().format('YYYY-MM-DD HH:mm:ss')
                            const momentEndPresale   = moment(endPresale);
                            const durationMillisecondsEndPresale = moment.duration(momentEndPresale.diff(starts));

                            if (durationMillisecondsStartPresale._milliseconds <= 0 && durationMillisecondsEndPresale._milliseconds >= 0) {
                                arrSlug.push(this.state.arrEvents[i].slug);
                            }
                        }else{
                            arrSlug.push(this.state.arrEvents[i].slug);
                        }
                    }
                }

            }

            if (arrSlug.length > 0){
                this.getAvailabilityTicketByEvents(arrSlug);
            }else{
                this.setState({
                    isLoadedDates: false,
                    msgErrorDates: "Non ci sono date disponibili"
                });
            }

        }


    }

    getAvailabilityTicketByEvents(arrSlug) {
        DataManager.getAvailabilityTicket(this.state.organizer.slug, arrSlug).then((result) => {
            if (result !== null) {
                const arrAvailability = result[0].availabilityTicket;
                if (typeof (arrAvailability) !== 'undefined' && arrAvailability !== null) {
                    this.setState({
                        resultDataAvailabilityAllEvents: arrAvailability,
                        isLoadedDates: true
                    });
                } else {
                    this.setState({
                        isLoadedDates: false,
                        msgErrorDates: "Errore nel caricamento delle date"
                    });
                }
            }else{
                this.setState({
                    isLoadedDates: false,
                    msgErrorDates: "Errore nel caricamento delle date"
                });
            }
        });
    }

    handleCloseInfoEvent = () => {
        this.setState({'showModalInfoEvent': false})
    };
    handleShowInfoEvent = () => {
        this.setState({'showModalInfoEvent': true})
    };

    addCartItem(e, objItem) {
        e.preventDefault();
        e.stopPropagation();
        const itemsSelected = this.state.itemsSelected;
        let idItem = objItem.id;

        if (this.state.eventSelected.jsonSettings.max_items_per_order === itemsSelected.length) {
            console.log("errore non puoi più aggiungere item");
        } else {
            let arrItems = [];
            arrItems = itemsSelected;

            if (arrItems.length === 0){
                arrItems.push(objItem);
                this.setState({
                    itemsSelected: arrItems
                });
                this.sumItems(arrItems);
            }else{
                var countItems = 0;
                if (arrItems.length > 0) {
                    for (var i = 0; i < arrItems.length; i++) {
                        let item = arrItems[i];
                        if (item.id === idItem){
                            countItems = countItems + 1;
                        }
                    }
                }

                for (var z = 0; z < this.state.resultDataQuotas.length; z++) {
                    let obj = this.state.resultDataQuotas[z];
                    if (obj.id === idItem){
                        if (obj.available_number > 0 && obj.available_number <= obj.size ){
                            if(countItems < obj.available_number){
                                arrItems.push(objItem);
                                this.setState({
                                    itemsSelected: arrItems
                                });
                                this.sumItems(arrItems);
                            }else{
                                toast.error("Non puoi più aggiungere biglietti al carrello perchè hai raggiunto il limite di disponibilità.", {
                                    theme: "colored",
                                    position: "bottom-center",
                                    autoClose: 3000
                                });
                            }
                        }else{
                            toast.error("Non puoi più aggiungere biglietti al carrello perchè sono esauriti.", {
                                theme: "colored",
                                position: "bottom-center",
                                autoClose: 3000
                            });
                        }
                    }
                }
            }

        }

    }

    sumItems(arrItems){
        if (arrItems.length > 0) {
            for (var i = 0; i < arrItems.length; i++) {
                let item = arrItems[i];
                let priceItem = item.default_price;
                let v1 = this.state.totItems * 100;
                let v2 = priceItem * 100;
                let totalV1V2 = (v1 + v2) / 100;
                const totFloatPriceItem = parseFloat(totalV1V2).toFixed(2);
                this.setState({
                    totItems: totFloatPriceItem
                });
            }
        }
        this.setState({
            statusTicketSelected: true
        });
    }

    removeCartItem(e, objItem) {
        e.preventDefault();
        e.stopPropagation();
        const itemsSelected = this.state.itemsSelected;
        let foundItem = false;
        if (itemsSelected.length > 0) {
            for (var i = 0; i < itemsSelected.length; i++) {
                if (itemsSelected[i].id === objItem.id) {
                    foundItem = true;
                }
            }
            if (foundItem) {
                const ids = itemsSelected.slice();
                const selectedItem = ids.find(element => element.id === objItem.id);
                ids.splice(ids.indexOf(selectedItem), 1);
                let v1 = this.state.totItems * 100;
                let v2 = objItem.default_price * 100;
                let totalV1V2 = (v1 - v2) / 100;
                const totFloatPriceItem = parseFloat(totalV1V2).toFixed(2);
                this.setState({
                    totItems: totFloatPriceItem
                });
                this.setState({
                    itemsSelected: ids
                });
            }
        }
    }

    totalItems(objItem) {
        let totItems = 0;
        for (var i = 0; i < this.state.itemsSelected.length; i++) {
            let item = this.state.itemsSelected[i];
            if (item.id === objItem.id) {
                totItems = totItems + 1;
            }
        }
        return totItems;
    }

    renderButtonsAddAndRemoveWithTotal(objItem){
        var checkItem = true;
        let idItem = objItem.id;

        for (var i = 0; i < this.state.resultDataQuotas.length; i++) {
            let item = this.state.resultDataQuotas[i];
            if (item.id === idItem){
                if (item.available_number > 0 && item.available === true){
                    checkItem = false;
                }
            }
        }

        return (
            <div className="buttons-cart">
                <Button
                    variant="outline-primary"
                    size="small"
                    disabled={checkItem}
                    onClick={(e) => this.removeCartItem(e, objItem)}
                >
                    <i className="fas fa-minus"/>
                </Button>
                <p className="margin-top-event">{this.totalItems(objItem)}</p>
                <Button
                    variant="outline-primary"
                    size="small"
                    disabled={checkItem}
                    onClick={(e) => this.addCartItem(e, objItem)}
                >
                    <i className="fas fa-plus"/>
                </Button>
            </div>
        );
    }

    renderSingleItemForEvent(objItem) {
        if (typeof (objItem.sales_channels) !== 'undefined' && objItem.sales_channels !== null && objItem.sales_channels.length > 0) {
            const checkWeb = objItem.sales_channels.every(item => item === 'web');
            if (checkWeb){
                return (
                    <div>
                        <div className="information-cart">
                            <p>{objItem.name.it}</p>
                            <p>Prezzo: <strong>{objItem.default_price}€</strong></p>
                        </div>
                        {this.renderButtonsAddAndRemoveWithTotal(objItem)}
                        {this.renderQuotasForEvent(objItem)}
                    </div>
                );
            }
        }

    }

    renderItemsForEvent() {
        return (
            <div>
                <h5>Totale: {this.state.totItems}€</h5>
                {this.state.resultDataItems.map((objItem) => (
                    <div>
                        {this.renderSingleItemForEvent(objItem)}
                    </div>
                ))}
            </div>
        );
    }

    renderQuotasForEvent(objItem) {
        let idItem = objItem.id;
        for (var i = 0; i < this.state.resultDataQuotas.length; i++) {
            let obj = this.state.resultDataQuotas[i];
            if (obj.id === idItem){
                if (obj.available_number > 0 && obj.available_number <= obj.size){
                    return (
                        <p className="text-success margin-bottom-text-danger">Sono disponibili {obj.available_number} biglietti</p>
                    );
                }else{
                    return (
                        <p className="text-danger margin-bottom-text-danger">Biglietti esauriti</p>
                    );
                }
            }
        }

    }

    actionWizard(e, objEvent) {
        e.preventDefault();
        e.stopPropagation();
        let valueMetaMappaByEvent = "";

        if (typeof (objEvent.item_meta_properties) !== 'undefined' && objEvent.item_meta_properties !== null) {
            if (Object.keys(objEvent.item_meta_properties).length > 0) {
                for (var j=0; j < Object.keys(objEvent.item_meta_properties).length; j++) {
                    var keyMeta = Object.keys(objEvent.item_meta_properties)[j];
                    var valueMeta = Object.values(objEvent.item_meta_properties)[j];
                    if (keyMeta === Utils.SlugMappa){
                        valueMetaMappaByEvent = valueMeta;
                    }
                }
            }
        }

        if (valueMetaMappaByEvent !== ""){
            if (valueMetaMappaByEvent === "n"){
                //free single ticket purchase without map
                this.setState({
                    statusTicketSelected: true
                });
                this.props.history.push({
                    pathname: '/individual',
                    state: {event : objEvent, organizer: this.state.organizer, typeEvent: Utils.TypeEventSingleWithoutMap, typeTicket: Utils.TicketSingle},
                    search: null
                });

            }else{
                //free single ticket purchase with map
                this.setState({
                    statusTicketSelected: true
                });
                this.props.history.push({
                    pathname: '/individual',
                    state: {event : objEvent, organizer: this.state.organizer, typeEvent: Utils.TypeEventSingleWithMap, typeTicket: Utils.TicketSingle},
                    search: null
                });
            }
        }else{
            Functions.alertMsgError(Messages.MsgErrorGeneral);
        }

        /*if(this.state.itemsSelected.length > 0){
            this.setState({
                statusTicketSelected: true
            });
            this.props.history.push({
                pathname: '/individual',
                state: {objEvent : objEvent, organizer: this.state.organizer, itemsSelected: this.state.itemsSelected, totItems: this.state.totItems},
                search: null
            });
        }else{
            this.setState({
                statusTicketSelected: false
            });
        }*/
    }

    renderEventInModal() {
        return (
            <Modal
                show={this.state.showModalInfoEvent}
                onHide={this.handleCloseInfoEvent}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{"zIndex": "10000000", "overflow-y": "auto", "height": "90vh"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Hai selezionato la data del {moment(this.state.eventSelected.date_from).format("lll")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <div style={{"overflow-y": "auto", "height": "70vh"}}>
                        <section className="section-area section-sp6 appointment-wraper">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-6 col-md-6">
                                        <div className="individual-form form-wraper">
                                            <h4 className="text-color-secondary">Seleziona biglietto</h4>
                                            <p className="text-danger margin-bottom-text-danger">Puoi
                                                selezionare {this.state.eventSelected.jsonSettings.max_items_per_order} biglietto/i</p>
                                            {this.renderItemsForEvent()}
                                            <Link onClick={(e) => this.actionWizard(e, this.state.eventSelected)} className="btn btn-outline-primary btn-sm">Prosegui con
                                                l'acquisto <i className="btn-icon-bx fas fa-shopping-cart"></i></Link>
                                            <p className="text-warning margin-bottom-text-warning" hidden={this.state.statusTicketSelected}>Attenzione, devi selezionare almeno un biglietto</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }

    actionAddTicketToCartOrWizard(e, objEvent) {
        e.preventDefault();
        e.stopPropagation();
        let event = null;
        event = objEvent;
        if (event.slug !== this.state.eventSelected.slug) {
            this.setState({
                totItems: 0,
                itemsSelected: []
            });
        }
        if (typeof (objEvent.seating_plan) !== 'undefined' && objEvent.seating_plan !== null) {
            this.actionWizard(e, objEvent);
        } /*else {
            this.state.resultDataItems = [];
            this.state.eventSelected = event;
            this.getItemsEvent(1, event);
        }*/
    }

    getItemsEvent(page, event) {
        DataManager.getItems(page, this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrItems = result[0].items;
                if (arrItems !== null && typeof (arrItems.results) !== 'undefined' && arrItems.results.length > 0) {
                    let dict = [];
                    arrItems.results.forEach((data) => {
                        dict.push(data);
                        this.setState({
                            resultDataItems: dict
                        });
                    });
                    if (typeof (arrItems.next) !== 'undefined' && arrItems.next != null) {
                        const page = arrItems.next.split('=').pop();
                        this.getItemsEvent(page, event);
                    } else {
                        this.getQuotasByEvent(event);
                        //this.handleShowInfoEvent(true);
                    }
                } else {
                    toast.error("Non esistono biglietti per questo evento", {
                        theme: "colored",
                        position: "bottom-center",
                        autoClose: 3000
                    });
                }
            } else {
                toast.error("Non è stato possibile recuperare i biglietti dell'evento", {
                    theme: "colored",
                    position: "bottom-center",
                    autoClose: 3000
                });
            }
        });
    }

    getQuotasByEvent(event) {
        DataManager.getQuotas(this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrQuotas = result[0].quotas;
                if (arrQuotas !== null && typeof (arrQuotas.results) !== 'undefined' && arrQuotas.results.length > 0) {
                    let dict = [];
                    arrQuotas.results.forEach((data) => {
                        dict.push(data);
                        this.setState({
                            resultDataQuotas: dict
                        });
                    });
                    this.handleShowInfoEvent(true);
                } else {
                    toast.error("Errore generale", {
                        theme: "colored",
                        position: "bottom-center",
                        autoClose: 3000
                    });
                }
            } else {
                toast.error("Non è stato possibile recuperare le quote disponibili per l'evento", {
                    theme: "colored",
                    position: "bottom-center",
                    autoClose: 3000
                });
            }
        });
    }

    getQuotasByAllEvents(event) {
        DataManager.getQuotas(this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrQuotas = result[0].quotas;
                if (arrQuotas !== null && typeof (arrQuotas.results) !== 'undefined' && arrQuotas.results.length > 0) {
                    let dict = this.state.resultDataQuotasAllEvents;
                    let newDict = {};
                    newDict[event.slug] = arrQuotas.results;
                    dict.push(newDict);
                    this.setState({
                        resultDataQuotasAllEvents: dict
                    });
                } else {
                    console.log("errore arrQuotas null");
                }
            } else {
                console.log("errore result");
            }
        });
    }

    renderListItemDates(objEvent){

        let slug = this.state.resultDataAvailabilityAllEvents['eventStatusMap'][objEvent.slug];
        if (typeof (slug) !== 'undefined' && slug !== null) {
            let status = this.state.resultDataAvailabilityAllEvents['eventStatusMap'][objEvent.slug].status;
            if (typeof (status) !== 'undefined' && status !== null) {
                if (status){
                    return <Link to="" onClick={(e) => this.actionAddTicketToCartOrWizard(e, objEvent)}>
                        {Functions.convertDate(objEvent.date_from)}
                        <i className="fa fa-angle-right"></i>
                    </Link>
                }else{
                    return <div className="btn-soldout">
                        {Functions.convertDate(objEvent.date_from)}
                        <img src={iconSoldOut} width="70" className="margin-left-soldout" alt="immagine di soldout"></img>
                    </div>
                }
            }else{
                return null;
            }
        }else{
            return null;
        }


    }

    renderDates() {

        if (this.state.msgErrorDates) {
            return (
                <ul className="service-menu">
                    <h6>Date disponibili</h6>
                    <div className="text-danger">{this.state.msgErrorDates}</div>
                </ul>
            );
        } else if (!this.state.isLoadedDates) {
            return (
                <ul className="service-menu">
                    <h6>Date disponibili</h6>
                    <div><Spinner animation="border" role="status" variant="primary" >
                        <span className="visually-hidden">Caricamento...</span>
                    </Spinner></div>
                </ul>
            );
        }else{
            return (
                <ul className="service-menu">
                    <h6>Date disponibili</h6>
                    {this.state.arrEvents.map((objEvent, i) => (
                        <li key={i.toString()}>{this.renderListItemDates(objEvent)}
                        </li>
                    ))}
                </ul>
            );
        }
    }

    render() {
        const {organizer, event} = this.state;

        return (
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{"zIndex": 1000000}}
                    >
                    </ToastContainer>
                </>
                <div>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp1">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 mb-30">
                                        <div className="detail-organizer-thumb-area">
                                            <img
                                                className="detail-organizer-thumb1 shadow-img-organizer img-event-detail"
                                                src={event.imgEvent !== ""  ? event.imgEvent : imgPlaceholder}
                                                alt={"Immagine evento " + event.name.it}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-30">
                                        <div className="heading-bx">
                                            <h2 className="title">{event.name.it}</h2>
                                            <h6 className="text-secondary"><i
                                                className="far fa-calendar-alt"></i>&nbsp;dal&nbsp;
                                                <Moment locale="it" format="DD MMMM YYYY">
                                                    {event.date_from}
                                                </Moment>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 mb-30">
                                        <div className="clearfix">
                                            <div className="head-text mb-30">
                                                <h4 className="title mb-15">Descrizione</h4>
                                                <p className="mb-0">{event.descriptionEvent}</p>
                                            </div>
                                        </div>
                                        <div className="clearfix">
                                            <div className="head-text mb-30">
                                                <h4 className="title mb-10">Info utili</h4>
                                            </div>
                                            <Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Info Location</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="mb-0">{event.location.it}</p>
                                                        <div>
                                                            <MapContainer center={[event.geo_lat, event.geo_lon]}
                                                                          zoom={13} scrollWheelZoom={false}>
                                                                <TileLayer
                                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                />
                                                                <Marker
                                                                    key="eventTicalo"
                                                                    position={[
                                                                        event.geo_lat,
                                                                        event.geo_lon
                                                                    ]}
                                                                />
                                                            </MapContainer>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Organizzatore</Accordion.Header>
                                                    <Accordion.Body>
                                                        <img className="img-event-detail-accordion"
                                                             src={organizer.imgOrganizer}
                                                             alt={"Immagine organizzatore " + organizer.name}/>
                                                        <p className="mb-0">{organizer.name}</p>
                                                        <p className="mb-0">{organizer.infoText}</p>
                                                        <div className="ft-contact">
                                                            <div className="contact-bx">
                                                                <div className="icon"><i
                                                                    className="fas fa-envelope"></i></div>
                                                                <div className="contact-info-element">
                                                                    <span><a
                                                                        href={"mailto:" + organizer.mailOrganizer}>Contattaci</a></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                {this.renderDates()}
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </>
        );

    }
}


export default withRouter(Event);
