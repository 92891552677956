import React, {Component} from 'react';
import Seo from "../../elements/seo";
import Organizer from '../organizer/organizer';
import DataManager from '../../DataManager';
import Moment from 'react-moment';
import 'moment/locale/it';
import 'moment-timezone';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Placeholder from "react-bootstrap/Placeholder";
import {Card} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';

//Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// config
import Utils from "../../Utils";
import Functions from "../../Functions";
import Messages from "../../Messages";

// Import Images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

class DetailOrganizer extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            showEvents:true,
            msgError: null,
            resultData : [],
            itemsGrouped: [],
            items: [],
            itemsGroupedTipology: [],
            organizer: this.props.location.state,
            eventWithSubscription: true
        };



    }

    componentDidMount() {
        if (typeof(this.props.location.state) !== 'undefined' && this.props.location.state != null) {
            this.getEvents(1, this.props.location.state.slug);
        }else{
            const urlOrganizer = window.location.href;
            const checkUrlOrganizer = urlOrganizer.includes("idOrganizer");
            if (checkUrlOrganizer){
                const query = new URLSearchParams(this.props.location.search);
                const slugOrganizer = query.get("idOrganizer");
                setTimeout(()=>{
                    this.getOrganizer(slugOrganizer);
                },100)
            }else{
                this.props.history.push({
                    pathname: '/error-404'
                });
            }
        }

    }

    getOrganizer(slugOrganizer){

        DataManager.getOrganizer(slugOrganizer).then((result) => {
            if (result !== null){
                const objOrganizer = result[0].organizer;
                if (objOrganizer !== null){
                    this.setState({
                        organizer: objOrganizer
                    });
                    this.getSettingsOrganizer(objOrganizer);
                }else{
                    const error = result[0].msgError;
                    if (typeof(error.message) !== 'undefined' && error.message !== null && error.message.includes('responseJson.organizer_info_text')) {
                        this.props.history.push({
                            pathname: '/error-404'
                        });
                    }else{
                        this.setState({
                            msgError : error,
                            isLoaded: false
                        });
                    }
                }
            }else{
                this.setState({
                    msgError : Messages.MsgErrorPageNotLoadedCorrectly,
                    isLoaded: false
                });
            }
        });

    }

    getSettingsOrganizer(objOrganizer){

        DataManager.getSettingsOrganizer(objOrganizer).then((result) => {
            if (result !== null){
                const objOrg = result[0].organizer;
                if (objOrg !== null){
                    this.setState({
                        organizer: objOrg
                    });
                    this.getEvents(1, objOrg.slug);
                }else{
                    const error = result[0].msgError;
                    if (typeof(error.message) !== 'undefined' && error.message !== null && error.message.includes('responseJson.organizer_info_text')) {
                        this.props.history.push({
                            pathname: '/error-404'
                        });
                    }else{
                        this.setState({
                            msgError : Messages.MsgErrorGeneral,
                            isLoaded: false
                        });
                    }
                }
            }else{
                this.setState({
                    msgError : Messages.MsgErrorPageNotLoadedCorrectly,
                    isLoaded: false
                });
            }
        });

    }

    getEvents(page, slug) {
        DataManager.getEvents(page, slug).then((result) => {
            if (result !== null){
                const arrEvents = result[0].events;

                if (arrEvents!== null && arrEvents.results.length > 0) {
                    arrEvents.results.forEach((data)=>{
                        this.state.resultData.push(data);
                    });
                    if (typeof(arrEvents.next) !== 'undefined' && arrEvents.next != null) {
                        const page = arrEvents.next.split('=').pop();
                        this.getEvents(page,slug)
                    } else {
                        this.setState({
                            showEvents: true
                        });
                        this.setState({
                            isLoaded: true
                        });
                        this.addEventData(this.state.resultData);
                    }
                }else{
                    this.setState({
                        isLoaded: true
                    });
                    console.log("no events");
                }
            }else{
                this.setState({
                    msgError : Messages.MsgErrorPageNotLoadedCorrectly,
                    isLoaded: false
                });
            }
        });
    }

    addEventData(jsonEventsByOrganizer){

        if (jsonEventsByOrganizer.length > 0){
            for (var i=0; i < jsonEventsByOrganizer.length; i++) {
                let objEvent = jsonEventsByOrganizer[i];
                let slugEvent = objEvent.slug;
                const link = Utils.URL_API_SERVER_ORGANIZERS + this.state.organizer.slug + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/settings/';

                let headers = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
                        'Host': Utils.BASE_URL_SERVER_CUSTOM
                    }
                };

                fetch(link, headers)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != null) {

                            objEvent.imgEvent = "";
                            if(typeof (responseJson.logo_image) !== 'undefined' && responseJson.logo_image !== null){
                                objEvent.imgEvent = responseJson.logo_image.toString();
                            }
                            objEvent.descriptionEvent = "";
                            if(typeof (responseJson.frontpage_text) !== 'undefined' && responseJson.frontpage_text !== null){
                                objEvent.descriptionEvent = responseJson.frontpage_text.it.toString();
                            }
                            objEvent.jsonSettings = responseJson;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                if (i === jsonEventsByOrganizer.length - 1){
                    let arr = Functions.addEventData(jsonEventsByOrganizer);
                    let arrTipology = arr[0].arrTipology;
                    let arrEvent = arr[0].arrEvent;
                    this.setState({
                        eventWithSubscription: arr[0].eventWithSubscription
                    });
                    if(arrTipology.length > 0 && arrEvent.length > 0){
                        this.setState({
                            itemsGroupedTipology: arrTipology
                        });
                        setTimeout(()=>{
                            if (arrEvent.length > 0){
                                this.setState({
                                    showEvents: false
                                });
                                this.setState({
                                    isLoadedCard: true
                                });
                                this.setState({
                                    itemsGrouped: arrEvent
                                });
                                this.setState({
                                    items: jsonEventsByOrganizer
                                });
                                this.setState({
                                    isLoaded: true
                                });
                            }else{
                                this.setState({
                                    showEvents: true
                                });
                                this.setState({
                                    isLoaded: false
                                });
                            }
                        },300)
                    }else{
                        this.setState({
                            showEvents: true
                        });
                        this.setState({
                            isLoaded: true
                        });
                    }


                }
            }
        }else{
            this.setState({
                showEvents: true
            });
            this.setState({
                isLoaded: true
            });
        }
    }

    onSelectEventInfo = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        let dictEvent = [];

        for (let i=0; i < this.state.itemsGrouped.length; i++) {
            let eventFinal = this.state.itemsGrouped[i];
            let titleEvent = eventFinal.name.it;
            if(titleEvent === item.name.it){
                dictEvent.push(eventFinal);
            }
        }
        this.props.history.push({
            pathname: '/organizer/event/detail-event',
            state: {arrEvents : dictEvent, organizer: this.state.organizer},
            search: `?idOrganizer=${this.state.organizer.slug}&idEvent=${item.name.it}`
        });
    };

    renderGroupTipology() {
        if (this.state.itemsGroupedTipology.length > 0) {
            var arrTipology = this.state.itemsGroupedTipology;
            return (
                <div>
                    {arrTipology.map((keyEvent) =>(
                        <div key={keyEvent.toString()}>
                            {this.renderGroupTipologyEvent(keyEvent)}
                        </div>
                    ))}
                </div>
            );
        }

    }

    moveRight = (e, id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft += 200;
    };

    moveLeft = (e,id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft -= 200;
    };

    renderGroupTipologyEvent(keyEvent) {
        let dictEvent = [];

        for (let i=0; i < this.state.itemsGrouped.length; i++) {
            let eventFinal = this.state.itemsGrouped[i];
            let k = eventFinal.tipology;
            if (k === keyEvent){
                if (dictEvent.length > 0){
                    let result = dictEvent.filter((event) => event.name.it.includes(eventFinal.name.it));
                    if (result.length === 0){
                        dictEvent.push(eventFinal);
                    }
                }else{
                    dictEvent.push(eventFinal);
                }
            }
        }

        let replaceKey = keyEvent.replace(" ", "");

        if (dictEvent.length > 0){
            return (
                <div>
                    <h3 className="title text-color-secondary">{keyEvent}</h3>
                    <div className="flex-container" id={"scrollEvents" + replaceKey} >
                        {dictEvent.map((item, i) =>(
                            <div className="scroll-horizontal" key={i.toString()}>
                                <Card hidden={!this.state.isLoadedCard} onClick={(e) => this.onSelectEventInfo(e, item)}>
                                    <Card.Img variant="top" src={item.imgEvent !== ""  ? item.imgEvent : imgPlaceholder} className="img-event"  />
                                    <Card.Body className="card-data">
                                        <p className="p-card-thick p-card-nowrap">{item.name.it}</p>
                                        <li className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;dal&nbsp;
                                            <Moment locale="it" format="DD MMM YYYY">
                                                 {item.date_from}
                                            </Moment>
                                        </li>
                                    </Card.Body>
                                </Card>
                                <Card hidden={this.state.isLoadedCard}>
                                    <Card.Img variant="top" src={imgPlaceholder} className="img-event" />
                                    <Card.Body className="card-data">
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                    {this.renderButtonArrows(replaceKey, Object.values(dictEvent).length)}
                </div>

            );
        }

    }

    renderButtonArrows(replaceKey, numEvent) {
        if (numEvent > 2) {
            return (
                <section style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="prevArrow" id={"prevArrow" + replaceKey} onClick={(e) => this.moveLeft(e,replaceKey)}>
                        <BsChevronLeft />
                    </div>
                    <div className="nextArrow" id={"nextArrow" + replaceKey} onClick={(e) => this.moveRight(e,replaceKey)}>
                        <BsChevronRight />
                    </div>
                </section>

            );
        }
    }

    subscriptionWithSelectionOfEvents(e){
        e.preventDefault();
        e.stopPropagation();

        if(this.state.resultData.length > 0){
            this.props.history.push({
                pathname: '/subscription',
                state: {events: this.state.items, organizer: this.state.organizer, typeTicket: Utils.TicketModularSubscription},
                search: null
            });
        }

    }

    render(){

        const { msgError, isLoaded, organizer, itemsGrouped, showEvents } = this.state;

        if (msgError) {
            return <div>Errore: {msgError}</div>;
        } else if (!isLoaded) {
            return <div><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>;
        }else{
            let dict = {};
            dict["title"] = Utils.NAME_CUSTOMER + " | " + organizer.name + " - Dettaglio organizzatore con i suoi eventi";
            dict["description"] = Utils.NAME_CUSTOMER + " | " + organizer.name + " - Dettaglio organizzatore con i suoi eventi";

            return (
                <>
                    <Seo propsSeo={dict}></Seo>
                    <Header />
                    <Organizer
                        organizer={organizer}
                    />
                    <div className="container" hidden={this.state.eventWithSubscription}>
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-30">
                                <Button variant="primary" onClick={(e) => this.subscriptionWithSelectionOfEvents(e)}>Abbonati</Button>
                            </div>
                        </div>
                    </div>

                    {
                        itemsGrouped !== null && itemsGrouped.length > 0
                            ?(
                                <div className="container" hidden={showEvents}>
                                    <div className="heading-bx text-center">
                                        <h3 className="title">Spettacoli</h3>
                                    </div>
                                    {this.renderGroupTipology()}
                                </div>
                            )
                            : <div>Errore: {Messages.MsgErrorNoEvents}</div>
                    }
                    <Footer />
                </>
            );
        }
    }
}

export default DetailOrganizer;
