import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Utils from "../Utils";

// Images
import Logo from '../../images/logo.png';

// Social Images
import facebook from '../../images/social/facebook.png';
import twitter from '../../images/social/twitter.png';
import instagram from '../../images/social/instagram.png';
import youtube from '../../images/social/youtube.png';

//Footer, component for managing the web app footer
class Footer extends Component{
	render(){
		return(
			<>

				<footer className="footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-lg-3 col-md-6">
									<div className="widget widget_info">
										<div className="footer-logo">
											<Link to="/"><img src={Logo} alt=""/></Link>
										</div>
										<div className="ft-contact">
											<p>Compatibile con tutti i browser e dispositivi mobili, Ticalo è un sistema flessibile, innovativo e sicuro progettato in modo intuitivo per gestire le prenotazioni di abbonamenti teatrali. </p>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-6">
								</div>
								<div className="col-xl-3 col-lg-3 col-12">
									<div className="widget widget_form">
										<h3 className="footer-title">Link Utili</h3>
										<ul>
											<li><Link to="/contact-us" target="_blank"><span>Contatti</span></Link></li>
									 		<li><Link to="/terms" target="_blank"><span>Termini e condizioni</span></Link></li>
											<li><a href="https://www.iubenda.com/privacy-policy/50491604" rel="noreferrer" target="_blank"><span>Privacy</span></a></li>
										</ul>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-6">
									<div className="widget widget_form">
										<h3 className="footer-title">Iscriviti alla Newsletter</h3>
										<form className="subscribe-form subscription-form mb-30">
											<div className="ajax-message"></div>
											<div className="input-group">
												<input name="email" required="required" className="form-control" placeholder="Email" type="email"/>
											</div>
											<button name="submit" value="Submit" type="submit" className="btn btn-secondary shadow w-100">Iscriviti</button>
										</form>
										<div className="footer-social-link">
											<ul>
												<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/TeatroCileaNapoli"><img src={facebook} alt="facebook cilea"/></a></li>
												<li><a target="_blank" rel="noreferrer" href="https://twitter.com/Teatro_Cilea"><img src={twitter} alt="twitter cilea"/></a></li>
                                                <li><a target="_blank" rel="noreferrer" href="https://instagram.com/teatrocilea/"><img src={instagram} alt="instagram cilea"/></a></li>
                                                <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCCPAWbCYcUcs8W7lqGqRQuA"><img src={youtube} alt="youtube cilea"/></a></li>
                                            </ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="footer-bottom">
							<div className="row">
								<div className="col-12 text-center">
									<p className="copyright-text">{Utils.NAME_CUSTOMER} v. {Utils.VERSION_WEBAPP}. Copyright © 2022 <a href="https://www.bluenetita.com" rel="noreferrer" target="_blank" className="text-secondary">Bluenet srl</a></p>
								</div>
							</div>
						</div>
					</div>
				</footer>

			</>
		);
	}
}

//porzione di codice che va subito dopo il link Contatti
//<li><Link to="/faq"><span>Faq</span></Link></li>

export default Footer;
