import React, { Component } from "react";

//pages wizard
import ProgressbarSingleEvent from "../pages/wizard/ProgressbarSingleEvent";
import Success from "../pages/wizard/Success";
import SelectMap from "../pages/wizard/SelectMap";
import Seo from "../elements/seo";
import SelectTicket from "../pages/wizard/SelectTicket";
import SelectUser from "../pages/wizard/SelectUser";
import SelectPayment from "../pages/wizard/SelectPayment";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";
import Functions from "../Functions";
import Messages from "../Messages";

export default class Form extends Component {

    constructor(props) {

        super(props);
        this.state = {
            step: 1,
            dateTimer: '',
            typeEvent: '',
            maxItemsForOrder: 0,
            reservationTime: 0,
            totalAmount: 0,
            totalSeat: 0,
            paymentMethod: '',
            ticketsSelected: [],
            arrUsers: [],
            arrUsersDuplicate: [],
            arrCartPositions: [],
            itemsEventSelected: [],
            countdownMinutes: 0,
            countdownSeconds: 0,
            countDownFinish: true,
            typeTicket: typeof(this.props.location.state) === 'undefined' || this.props.location.state === null ? [] : this.props.location.state.typeTicket,
            event: this.props.location.state.event,
            organizer: this.props.location.state.organizer
        };

    }

    componentDidMount() {

        if (typeof(this.props.location) !== 'undefined' && this.state.location !== null) {
            this.setState(
                {
                    typeEvent: this.props.location.state.typeEvent
                }
            );
        }

        const urlWizard = window.location.href;
        const checkUrlWizard = urlWizard.includes("checkout");
        if (checkUrlWizard) {
            this.setState({
                step: 5
            });
        }else{
            this.countdownTimer();
        }

    }

    countdownTimer = () => {

        let dateAndhourCurrent = new Date();
        dateAndhourCurrent.setMinutes(dateAndhourCurrent.getMinutes() + 15);
        this.setState(
            {
                dateTimer: dateAndhourCurrent
            }
        );

        const timeInterval= setInterval(() => {
            const countdownDateTime = new Date(dateAndhourCurrent).getTime();
            const currentTime = new Date().getTime();
            const remainingDayTime = countdownDateTime - currentTime;
            const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
            const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

            this.setState(
                {
                    countdownMinutes: totalMinutes
                }
            );
            this.setState(
                {
                    countdownSeconds: totalSeconds
                }
            );

            if (remainingDayTime < 0) {
                clearInterval(timeInterval);
                this.setState(
                    {
                        countDownFinish: false
                    }
                );
            }

        }, 1000);

    };

    setUsersDuplicate = (arrUsers) => {
        this.setState({
            arrUsersDuplicate: arrUsers,
        });
    };

    nextStep = (arrTicketsSelected, totTickets, objEvent, objOrganizer, arrUsers, dateT, arrCP, arrEventsSelected, maxItems) => {
        if(this.state.countDownFinish !== false) {
            const { step } = this.state;

            this.setState({
                maxItemsForOrder: maxItems,
                itemsEventSelected: arrEventsSelected,
                ticketsSelected: arrTicketsSelected,
                totalAmount: totTickets,
                event: objEvent,
                organizer: objOrganizer,
                arrUsers: arrUsers,
                dateTimer: dateT,
                arrCartPositions: arrCP
            });

            this.setState({
                step: step + 1,
            });
        }else{
            Functions.alertMsgError(Messages.MsgTimerExpired);
        }

    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    renderMap(values){
        if(values.typeEvent === Utils.TypeEventSingleWithoutMap){
            return <SelectTicket
                nextStep={this.nextStep}
                values={values}
                valuesData={this.props.location.state}
            />
        }else{
            return <SelectMap
                nextStep={this.nextStep}
                values={values}
                valuesData={this.props.location.state}
            />
        }
    }

    render() {

        const { step } = this.state;
        const {ticketsSelected, arrUsers, event, organizer,
            paymentMethod, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, typeEvent, arrCartPositions, countdownMinutes,
            countdownSeconds, countDownFinish, propsSeo, typeTicket, arrUsersDuplicate } = this.state;

        const values = { ticketsSelected, arrUsers, event, organizer,
            paymentMethod, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, typeEvent, arrCartPositions, countdownMinutes,
            countdownSeconds, countDownFinish, propsSeo, typeTicket, arrUsersDuplicate};

        let dict = {};
        dict["title"] = Utils.NAME_CUSTOMER + " | Acquisto biglietto " + this.props.location.state.event.name.it;
        dict["description"] = Utils.NAME_CUSTOMER + " | Acquisto biglietto " + this.props.location.state.event.name.it;


        return (
            <>
                <Seo propsSeo={dict}></Seo>
                <Header />
                <section className="section-area section-sp1 blog-area">
                    <form id="msform">
                        {}
                        <ProgressbarSingleEvent step={step} />
                        <div className="App">
                            <div className="btn-group my-3">
                                <>
                                    <div className={Functions.renderClassTimer(this.state.countdownMinutes, this.state.countdownSeconds)}>{this.state.countdownMinutes}&nbsp;minuti&nbsp;:&nbsp;{this.state.countdownSeconds}&nbsp;secondi</div>
                                </>
                            </div>
                        </div>
                        {}
                        {
                            {
                                1: (
                                    this.renderMap(values)
                                ),
                                2: (
                                    <SelectUser
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        setUsersDuplicate={this.setUsersDuplicate}
                                        values={values}
                                        valuesData={this.props.location.state}
                                    />
                                ),
                                3: (
                                    <SelectPayment
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        values={values}
                                        valuesData={this.props.location.state}
                                    />
                                ),
                                4: <Success values={values}/>,
                            }[step]
                        }
                    </form>
                </section>
                <Footer />
            </>
        );
    }
}
