import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Elements
import SeatMap from "./SeatMap/SeatMap";
import ButtonWizard from "./ButtonWizard";
import Messages from "../../Messages";
import Functions from "../../Functions";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

export default class SelectMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalAmount: 0,
            cartPositionsList: [],
            dateTimer: ''
        };

    }

    componentDidMount() {

    }

    continue = (e) => {

        e.preventDefault();
        if (this.state.cartPositionsList.length > 0){
            let total = 0;
            for (var i=0; i < this.state.cartPositionsList.length; i++) {
                let seat = this.state.cartPositionsList[i];
                let presale = 0;
                if (typeof (seat.typeTicket.bundles) !== 'undefined' && seat.typeTicket.bundles !== null && seat.typeTicket.bundles.length > 0) {
                    presale = seat.typeTicket.bundles[0].designated_price * 100;
                }
                const floatTotal = parseFloat(seat.typeTicket.default_price).toFixed(2);
                let v1 = total*100;
                let v2 = floatTotal*100;
                let totalV1V2 = (v1 + v2 + presale)/100;
                total = parseFloat(totalV1V2.toString()).toFixed(2);
            }
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.props.nextStep(this.props.values.ticketsSelected, total, this.props.values.event, this.props.values.organizer, [], this.props.values.dateTimer, this.state.cartPositionsList, [], 0);
        }else{
            Functions.alertMsgError(Messages.MsgSelectOneSeat);
        }
    };

    getSeatsSelected = (arr, totAmount) => {
        this.setState({
            cartPositionsList: arr,
            totalAmount: totAmount
        });
    };

    render() {
        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <SeatMap values={this.props.values} sendseats={this.getSeatsSelected} />
                                    </div>
                                    <div className="col-lg-3">
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                <ul className="service-menu">
                                                    <h6>Evento</h6>
                                                    <p className="p-card-thick">{this.props.values.event.name.it}</p>
                                                    <img src={this.props.values.event.imgEvent !== ""  ? this.props.values.event.imgEvent : imgPlaceholder}
                                                         className="img-event-sidebar" alt="immagine dell'evento" />
                                                    <p className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;
                                                        {Functions.convertDate(this.props.values.event.date_from)}
                                                    </p>
                                                    <p className="title1-modal-event">Tipologia evento:&nbsp;</p>
                                                    <p className="title2-modal-event truncate-event">{this.props.values.event.tipology}</p>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.continue} text="Avanti" />
                    </div>

                </fieldset>
            </>
        );

    }

}
