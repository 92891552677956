import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Elements
import ListSector from "./ListSector";
import ButtonWizard from "./ButtonWizard";
import Messages from "../../Messages";
import Functions from "../../Functions";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";
import DataManager from "../../DataManager";

export default class SelectTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totTickets: 0,
            ticketsSelected: []
        };
    }

    componentDidMount() {

        if (typeof(this.props.values) !== 'undefined' && this.state.values !== null) {
            if (this.props.values.ticketsSelected.length > 0) {
                this.setState({
                    totTickets: this.props.values.totalAmount,
                    ticketsSelected: this.props.values.ticketsSelected
                });
            }
        }

        const arrTypeTicket = JSON.parse(localStorage.getItem("arrTypeTicket"));
        if (typeof (arrTypeTicket) === 'undefined' || arrTypeTicket === null) {
            this.loadTypeTicket();
        }

    }

    continue = (e) => {
        e.preventDefault();
        if (this.state.ticketsSelected.length > 0){
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.props.nextStep(this.state.ticketsSelected, this.state.totTickets, this.props.valuesData.event, this.props.valuesData.organizer, [], this.state.dateTimer, [], [], 0);
        }else{
            Functions.alertMsgError(Messages.MsgSelectOneTicket);
        }
    };

    loadTypeTicket = () => {
        DataManager.getTypeTicket().then((result) => {
            if (result !== null) {
                const arrTypeTicket = result[0].typeTicket;
                if (typeof (arrTypeTicket) !== 'undefined' && arrTypeTicket !== null) {
                    setTimeout(()=>{
                        localStorage.setItem("arrTypeTicket", JSON.stringify(arrTypeTicket));
                    },500)
                } else {
                    console.log("error typeTicket")
                }
            }else{
                console.log("error typeTicket")
            }
        });
    };

    getTicketsSelected = (arr, tot) => {
        this.setState({
            totTickets: tot,
            ticketsSelected: arr
        });
    };

    render() {

        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <ListSector values={this.props.values} valuesData={this.props.valuesData} sendTickets={this.getTicketsSelected} />
                                    </div>
                                    <div className="col-lg-3">
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                <ul className="service-menu">
                                                    <h6>Evento</h6>
                                                    <p className="p-card-thick">{this.props.values.event.name.it}</p>
                                                    <img src={this.props.values.event.imgEvent !== ""  ? this.props.values.event.imgEvent : imgPlaceholder}
                                                         className="img-event-sidebar" alt="immagine dell'evento" />
                                                    <p className="date li-color-card"><i className="far fa-calendar-alt"></i>&nbsp;
                                                        {Functions.convertDate(this.props.values.event.date_from)}
                                                    </p>
                                                    <p className="title1-modal-event">Tipologia evento:&nbsp;</p>
                                                    <p className="title2-modal-event">{this.props.values.event.tipology}</p>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.continue} text="Avanti" />
                    </div>

                </fieldset>
            </>
        );

    }

}
