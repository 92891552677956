import React, { Component } from 'react';
import 'moment-timezone';
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Utils from "../Utils";
import Placeholder from "react-bootstrap/Placeholder";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {Link} from 'react-router-dom';

// Import Images
import imgPlaceholder from "../../images/placeholderTicalo.png";

//config
import Messages from "../Messages";

class OrganizerComponent extends Component{


	constructor(props) {
		super(props);
		this.state = {
			msgError: null,
			isLoaded: false,
			isLoadedCard: false,
			organizer: null,
			itemsOrganizer: []
		};
	}

    componentDidMount() {
        this.fetchOrganizers()

    }

	fetchOrganizers() {

		let headers = {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
				'Host': Utils.BASE_URL_SERVER_CUSTOM
			}
		};

		let link = Utils.URL_API_SERVER_ORGANIZERS;

		fetch(link, headers)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.results.length > 0) {
					var arrOrganizers = responseJson.results;
					for (var i=0; i < arrOrganizers.length; i++) {
						let objOrganizer = arrOrganizers[i];
						objOrganizer.infoText = "";
						objOrganizer.homepageText = "";
						objOrganizer.imgOrganizer = "";
						objOrganizer.mailOrganizer = "";
					}
					this.fetchSettingsOrganizer(arrOrganizers);
				}else{
					this.setState({
						msgError: Messages.MsgErrorFailedLoad,
						isLoadedCard: false,
						isLoaded: false
					});
				}
			})
			.catch((error) => {
				this.setState({
					msgError : Messages.MsgErrorGeneral,
					isLoaded: false,
					isLoadedCard: false
				});
			});

	}

	fetchSettingsOrganizer(arrOrganizers){
		if (arrOrganizers.length > 0){
			for (var i=0; i < arrOrganizers.length; i++) {
				let objOrganizer = arrOrganizers[i];
				let slug = objOrganizer.slug;
				const link = Utils.URL_API_SERVER_ORGANIZERS + slug + '/settings/';

				let headers = {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
						'Host': Utils.BASE_URL_SERVER_CUSTOM
					}
				};

				fetch(link, headers)
					.then((response) => response.json())
					.then((responseJson) => {
						if (responseJson != null) {
							let infoText = responseJson.organizer_info_text.it.toString();
							let homepageText = responseJson.organizer_homepage_text.it.toString();
							let imgOrganizer = "";
							if (typeof (responseJson.organizer_logo_image) !== 'undefined' && responseJson.organizer_logo_image !== null) {
								imgOrganizer = responseJson.organizer_logo_image.toString();
							}
							let mailOrganizer = "";
							if (typeof (responseJson.contact_mail) !== 'undefined' && responseJson.contact_mail !== null) {
								mailOrganizer = responseJson.contact_mail.toString();
							}
							objOrganizer.mailOrganizer = mailOrganizer;
							objOrganizer.infoText = infoText;
							objOrganizer.homepageText = homepageText;
							objOrganizer.imgOrganizer = imgOrganizer;
						}
					})
					.catch((error) => {
						console.error(error);
					});
				if (i === arrOrganizers.length - 1){
					setTimeout(()=>{
						this.setState({
							itemsOrganizer: arrOrganizers,
							isLoadedCard: true,
							isLoaded: true
						});
					},1000)
				}
			}

		}else{
			this.setState({
				msgError : Messages.MsgErrorFailedLoad,
				isLoaded: false,
				isLoadedCard: false
			});
		}
	}

	renderButtonArrows(replaceKey, num) {
		if (num > 2) {
			return (
				<section style={{ display: "flex", justifyContent: "space-between" }}>
					<div className="prevArrow" id={"prevArrow" + replaceKey} onClick={(e) => this.moveLeft(e,replaceKey)}>
						<BsChevronLeft />
					</div>
					<div className="nextArrow" id={"nextArrow" + replaceKey} onClick={(e) => this.moveRight(e,replaceKey)}>
						<BsChevronRight />
					</div>
				</section>

			);
		}
	}

	moveRight = (e, id) => {
		e.preventDefault();
		//const elScroll = document.getElementById("scrollEvents" + id);
		const elScroll = document.getElementById("scrollTeatri");
		elScroll.scrollLeft += 200;
	};

	moveLeft = (e,id) => {
		e.preventDefault();
		//const elScroll = document.getElementById("scrollEvents" + id);
		const elScroll = document.getElementById("scrollTeatri");
		elScroll.scrollLeft -= 200;
	};

	render(){
		const { msgError, isLoaded, isLoadedCard, itemsOrganizer } = this.state;

		if (msgError) {
			return <div>Errore: {msgError}</div>;
		} else if (!isLoaded) {
			return <div><Spinner animation="border" role="status" variant="primary" >
				<span className="visually-hidden">Caricamento...</span>
			</Spinner></div>;
		}else{
			return (

				<section className="section-area section-sp1 blog-area">
					<div className="container">
						<div className="flex-container" id={"scrollTeatri"} >
							{itemsOrganizer.map((item, i) =>(
								<div className="scroll-horizontal" key={i.toString()}>
									<Link to={{
										pathname: '/organizer/detail-organizer',
										state: item,
										search: `?idOrganizer=${item.slug}`
									}}>
									<Card hidden={!isLoadedCard}>
										<Card.Img variant="top" src={item.imgOrganizer !== ""  ? item.imgOrganizer : imgPlaceholder} className="img-event"  />
										<Card.Body className="card-data">
											<p className="p-card-thick p-card-nowrap">{item.name}</p>
											<p className="p-card-thick-sample">{item.infoText}</p>
										</Card.Body>
									</Card>
								    </Link>
									<Card hidden={isLoadedCard}>
										<Card.Img variant="top" src={imgPlaceholder} className="img-event" />
										<Card.Body className="card-data">
											<Placeholder as={Card.Title} animation="glow">
												<Placeholder xs={6} />
											</Placeholder>
											<Placeholder as={Card.Text} animation="glow">
												<Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
											</Placeholder>
										</Card.Body>
									</Card>
								</div>
							))}
						</div>
						{this.renderButtonArrows("scrollTeatri", itemsOrganizer.length)}
					</div>

				</section>

			);

		}

	}
}

export default OrganizerComponent;
