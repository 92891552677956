import Utils from "./Utils";

//DataManager, component with all API calls
function getOrganizer(slugOrganizer) {

    const link = Utils.URL_API_SERVER_ORGANIZERS + slugOrganizer;
    let objOrganizer = null;

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };


    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson != null) {
                objOrganizer = responseJson;
                objOrganizer.infoText = "";
                objOrganizer.homepageText = "";
                objOrganizer.imgOrganizer = "";
                objOrganizer.mailOrganizer = "";
                return [
                    {"organizer": objOrganizer, "msgError": ""}
                ];
            }else{
                return [
                    {"organizer": null, "msgError": "Errore Organizzatore"}
                ];
            }
        })
        .catch(error => {
            return [
                {"organizer": null, "msgError": error}
            ];
        });

};

function getSettingsOrganizer(objOrganizer){

    const link = Utils.URL_API_SERVER_ORGANIZERS + objOrganizer.slug + '/settings/';

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {

            if (responseJson != null) {
                let infoText = "";
                if (typeof(responseJson.organizer_info_text.it) !== 'undefined' && responseJson.organizer_info_text.it != null
                    && responseJson.organizer_info_text.it !== "") {
                    infoText = responseJson.organizer_info_text.it.toString();
                }

                let homepageText = "";
                if (typeof(responseJson.organizer_homepage_text.it) !== 'undefined' && responseJson.organizer_homepage_text.it != null
                    && responseJson.organizer_homepage_text.it !== "") {
                    homepageText = responseJson.organizer_homepage_text.it.toString();
                }

                let mailOrganizer = "";
                if (responseJson.contact_mail !== null){
                    mailOrganizer = responseJson.contact_mail.toString();
                }

                let imgOrganizer = "";
                if (responseJson.organizer_logo_image !== null){
                    imgOrganizer = responseJson.organizer_logo_image.toString();
                }
                objOrganizer.mailOrganizer = mailOrganizer;
                objOrganizer.infoText = infoText;
                objOrganizer.homepageText = homepageText;
                objOrganizer.imgOrganizer = imgOrganizer;

                return [
                    {"organizer": objOrganizer, "msgError": ""}
                ];
            }else{
                return [
                    {"organizer": null, "msgError": "Errore Organizzatore"}
                ];
            }
        })
        .catch(error => {
            return [
                {"organizer": null, "msgError": error}
            ];
        });

};

function getEvents(page, slugOrganizer) {

    //let dateNow = new Date().toISOString();
    //console.log(dateNow)
    //console.log("dateNow")

    let params = {'live': 'true', 'is_future': 'true'};
    //let params = {'live': 'true', 'date_from_after': "2023-06-23T10:00:00Z"};
    let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');

    let link = Utils.URL_API_SERVER_ORGANIZERS + slugOrganizer + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + '?page=' + page;
    link += `&${query}`;

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson != null) {
                return [
                    {"events": responseJson, "msgError": ""}
                ];
            }else{
                console.log("no events");
            }
        })
        .catch(error => {
            return [
                {"events": null, "msgError": error}
            ];
        });

};

function getItems(page, slugOrganizer, slugEvent) {

    let link = Utils.URL_API_SERVER_ORGANIZERS + slugOrganizer + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/items/?page=' + page;

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson != null) {
                return [
                    {"items": responseJson, "msgError": ""}
                ];
            }else{
                console.log("no items");
            }
        })
        .catch(error => {
            return [
                {"items": null, "msgError": error}
            ];
        });

};

function getQuotas(slugOrganizer, slugEvent) {

    let link = Utils.URL_API_SERVER_ORGANIZERS + slugOrganizer + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/quotas/?with_availability=true';

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson != null) {
                return [
                    {"quotas": responseJson, "msgError": ""}
                ];
            }else{
                console.log("no quotas");
            }
        })
        .catch(error => {
            return [
                {"quotas": null, "msgError": error}
            ];
        });

};

function getCategories(slugOrganizer, slugEvent) {

    let link = Utils.URL_API_SERVER_ORGANIZERS + slugOrganizer + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/categories/';

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson != null) {
                return [
                    {"categories": responseJson, "msgError": ""}
                ];
            }else{
                console.log("no categories");
            }
        })
        .catch(error => {
            return [
                {"categories": null, "msgError": error}
            ];
        });

};

function getAvailabilityTicket(slugOrganizer, arrSlug) {

    let params = {'eventoSlugList': arrSlug, 'organizzatoreSlug': slugOrganizer};
    const paramsString = JSON.stringify(params);
    let headers = {
        method: 'POST',
        body: paramsString,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    //let link = "http://192.168.1.252:8081/siae_bridge_api/api/get_disponibilita_by_lista_eventi";
    let link = Utils.URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_LISTA_EVENTI;

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            return [
                {"availabilityTicket": responseJson, "msgError": ""}
            ];
        })
        .catch(error => {
            return [
                {"availabilityTicket": null, "msgError": error}
            ];
        });

};

function getAvailabilitySector(slugOrganizer, arrSlug) {

    let params = {'eventoSlugList': arrSlug, 'organizzatoreSlug': slugOrganizer};
    const paramsString = JSON.stringify(params);

    let headers = {
        method: 'POST',
        body: paramsString,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    //let link = "http://192.168.1.252:8081/siae_bridge_api/api/get_disponibilita_by_categoria";
    let link = Utils.URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_CATEGORIA;

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            return [
                {"availabilitySector": responseJson, "msgError": ""}
            ];
        })
        .catch(error => {
            return [
                {"availabilitySector": null, "msgError": error}
            ];
        });

};

function getAvailabilityMapByCategory(slugOrganizer, arrSlug) {

    let params = {'eventoSlugList': arrSlug, 'organizzatoreSlug': slugOrganizer};
    const paramsString = JSON.stringify(params);

    let headers = {
        method: 'POST',
        body: paramsString,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    //let link = "http://192.168.1.252:8081/siae_bridge_api/api/get_disponibilita_by_categoria";
    let link = Utils.URL_API_SERVER_CUSTOM_GET_DISPONIBILITA_CATEGORIA;

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson.mappa != null) {
                return [
                    {"map": responseJson, "msgError": ""}
                ];
            }else{
                return [
                    {"map": null, "msgError": "Errore Mappa"}
                ];
            }
        })
        .catch((error) => {
            return [
                {"map": null, "msgError": error}
            ];
        });

};

function getAvailabilityMap(slugOrganizer, arrSlug) {

    let params = {'eventoSlugList': arrSlug, 'organizzatoreSlug': slugOrganizer};
    const paramsString = JSON.stringify(params);

    let headers = {
        method: 'POST',
        body: paramsString,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    //let link = "http://192.168.1.252:8081/siae_bridge_api/api/get_disponibilita";
    let link = Utils.URL_API_SERVER_CUSTOM_GET_DISPONIBILITA;

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            if (responseJson.mappa != null) {
                return [
                    {"map": responseJson, "msgError": ""}
                ];
            }else{
                return [
                    {"map": null, "msgError": "Errore Mappa"}
                ];
            }
        })
        .catch((error) => {
            return [
                {"map": null, "msgError": error}
            ];
        });

};

function getTypeTicket() {

    let headers = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + Utils.TOKEN_SERVER_CUSTOM,
            'Host': Utils.BASE_URL_SERVER_CUSTOM
        }
    };

    let link = Utils.URL_API_SERVER_CUSTOM_GET_TIPO_TITOLI;

    return fetch(link, headers)
        .then((response) => {
            if(!response.ok) throw response.status;
            else return response.json();
        })
        .then((responseJson) => {
            return [
                {"typeTicket": responseJson, "msgError": ""}
            ];
        })
        .catch(error => {
            return [
                {"typeTicket": null, "msgError": error}
            ];
        });

};

export default {getOrganizer, getSettingsOrganizer, getEvents, getItems, getQuotas, getCategories, getAvailabilityTicket, getAvailabilitySector, getAvailabilityMapByCategory, getAvailabilityMap, getTypeTicket}
