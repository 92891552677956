import React from "react";

// Import Images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

class Organizer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            organizer: this.props.organizer
        };
    }

    componentDidMount() {

    }

    renderEmail() {
        if (this.state.organizer.mailOrganizer !== ""){
            return (
                <div className="ft-contact mt-15">
                    <div className="contact-bx">
                        <div className="icon"><i className="fas fa-envelope"></i></div>
                        <div className="contact-info-element">
                            <span><a href={"mailto:" + this.state.organizer.mailOrganizer}>Contattaci</a></span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { organizer } = this.state;

        return (
            <section className="section-sp1 detail-organizer-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-30">
                            <div className="detail-organizer-thumb-area">
                                <img className="detail-organizer-thumb1 shadow-img-organizer img-event-detail" src={organizer.imgOrganizer !== ""  ? organizer.imgOrganizer : imgPlaceholder} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="heading-bx">
                                <h2 className="title">{organizer.name}</h2>
                                <h6 className="text-secondary">{organizer.infoText}</h6>
                                <p>{organizer.homepageText}</p>
                                {this.renderEmail()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Organizer;
