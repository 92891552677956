import React, { Component } from 'react';

// Import Images
import bgHome from '../../images/main-banner/bg-home.jpg';
import masks from '../../images/theater-masks.png';


class aboutSection extends Component{
	render(){
		return(
			<>

				<div className="main-banner" style={{backgroundImage: "url("+bgHome+")"}}>
					<div className="container inner-content">
						<div className="row align-items-center">
							<div className="col-lg-7 col-md-6 col-sm-7">
								<h1 className="text-primary">Ticalo è un sistema flessibile, innovativo per l'acquisto di biglietti per eventi</h1>
							</div>
						</div>
					</div>
				</div>

			</>
		);
	}
}

export default aboutSection;
