import React from "react";

function getColor(isBooked, isSelected) {
    if (isSelected) {
        return "selected";
    } else if (isBooked) {
        return "booked";
    } else {
        return "default_seat";
    }
}

const Seat = props => {
    const isBooked = props?.status === false;
    var fontSizeSeat = 8;
    if (props.lengthSeat > 3){
        fontSizeSeat = 6;
    }

    return (
        <div
            data-for="seat-tooltip"
            data-tip="seat-tooltip"
            data-iscapture="true"
            className={`${props.className} ${getColor(isBooked, props.isSelected)}`}
            id={props.id}
            onMouseEnter={e => {

                props.onHover({...props.seat, row_number: props.rowNumber});
                if (isBooked) {
                    e.target.style.cursor = "not-allowed";
                } else {
                    e.target.style.cursor = "pointer";
                }
            }}
            onMouseLeave={e => {
                props.onHover(null);
                e.target.style.cursor = "";
            }}
            onClick={e => {
                if (isBooked) {
                    return;
                }
                if (props.isSelected) {
                    props.onDeselect(props.seat);
                } else {
                    props.onSelect({...props.seat, row_number: props.rowNumber});
                }
            }}
            style={{
                width: 18,
                height: 18,
                marginLeft: 5,
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                fontSize: fontSizeSeat,
                borderRadius: 50,
                borderWidth: 1,
                borderColor: '#fff',
                display: 'flex',
                position: 'absolute',
                left: props.position?.x,
                top: props.position?.y,
                backgroundColor: getColor(isBooked, props.isSelected) === 'booked' ? '#666666' : getColor(isBooked, props.isSelected) === 'selected' ? '#209f84' : props.color
            }}

        >{props.seat.seat_number}</div>
    );
};

export default Seat;
