import { Component } from 'react';
import {toast} from "react-toastify";
import Utils from "./Utils";
import moment from "moment";

//Functions, component with all generic functions
class Functions extends Component{

    static renderClassTimer = (minutes, seconds) =>{
        if (minutes >= 10){
            return "timer-background-green";
        }else if (minutes >= 5 && minutes < 10){
            return "timer-background-yellow";
        }else if (minutes > 0 && minutes < 5){
            return "timer-background-red";
        }else if (minutes === 0 && seconds <= 59){
            if (minutes === 0 && seconds === 0) {
                return "timer-background";
            }else{
                return "timer-background-red";
            }
        }else{
            return "timer-background";
        }
    };

    static alertMsgError = (string) =>{
        toast.error(string, {
            theme: "colored",
            position: "bottom-center",
            autoClose: 3000
        });
    };

    static nameProduct = (name) =>{
        const arrTypeTicket = JSON.parse(localStorage.getItem("arrTypeTicket"));
        if (typeof (arrTypeTicket) !== 'undefined' && arrTypeTicket !== null) {
            let index = name.lastIndexOf(".");
            let keyName = name.substring(index+1);
            let idName = name.substring(0,index);
            if (idName === "1"){
                /*const arr = Object.keys(Utils.TYPE_TITLE).filter((element) => element.includes(keyName));
                if (arr.length > 0){
                    return Utils.TYPE_TITLE[keyName];
                }*/
                const arr = arrTypeTicket.filter((element) => element.codice.includes(keyName));
                if (arr.length > 0){
                    return arr[0].descrizione;
                }
                return "";
            }else if (idName === "2"){
                /*const arr = Object.keys(Utils.TYPE_TITLE).filter((element) => element.includes(keyName));
                if (arr.length > 0){
                    return Utils.TYPE_TITLE[keyName];
                }*/
                const arr = arrTypeTicket.filter((element) => element.codice.includes(keyName));
                if (arr.length > 0){
                    return arr[0].descrizione;
                }
                return "";
            }else{
                return "";
            }
        }else{
            return "";
        }
    };


    static convertDate (date){
        moment.locale('it')
        let dateLocal = date.toLocaleString('it-IT');
        const dateFinal = moment(new Date(dateLocal))
            .utc().format('lll');
        //per convertire in base alla nazione aggiungere il timezone
        /*const timezone = "Europe/Rome";
        const dateFinal = moment(new Date(dateLocal))
            .utc().tz(timezone).format('lll');*/
        return dateFinal;
    }

    static convertDateByTimezone (date){
        moment.locale('it')
        let dateLocal = date.toLocaleString('it-IT');
        /*const dateFinal = moment(new Date(dateLocal))
            .utc().format('lll');*/
        //per convertire in base alla nazione aggiungere il timezone
        const timezone = "Europe/Rome";
        const dateFinal = moment(new Date(dateLocal))
            .utc().tz(timezone).format('lll');
        return dateFinal;
    }

    static addEventData(jsonEventsByOrganizer){

        let arrTipology = [];
        var arrEventGrouped = [];
        var arrEvent = [];

        for (var i=0; i < jsonEventsByOrganizer.length; i++) {
            let event = null;
            event = jsonEventsByOrganizer[i];
            event.selectedEvent = false;
            event.tipology = "";
            event.publication = "";
            event.imgEvent = "";
            event.descriptionEvent = "";
            event.jsonSettings = null;
            let dict = {};

            /*if (arrEventGrouped.length > 0){
                    if (defaultValue !== event.name.it){
                        defaultValue = event.name.it;
                        dict[event.name.it] = event;
                        arrEventGrouped.push(dict);
                    }else{
                        dict[event.name.it] = event;
                        arrEventGrouped.push(dict);
                    }
                }else{
                    dict[event.name.it] = event;
                    arrEventGrouped.push(dict);
                    defaultValue = event.name.it;
                }*/

            dict[event.name.it] = event;
            arrEventGrouped.push(dict);
        }

        let eventWithSubscription = true;

        arrEventGrouped.forEach((value, i) => {
            if (Object.keys(value) !== 0) {
                var keyEvent = Object.keys(value)[0];
                var event = value[keyEvent];
                if (Object.keys(event.item_meta_properties).length > 0) {
                    for (var j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                        var keyMeta = Object.keys(event.item_meta_properties)[j];
                        var valueMeta = Object.values(event.item_meta_properties)[j];

                        if (keyMeta === 'abbonamento'){
                            if(valueMeta === 'n'){
                                arrEvent.push(event);
                            }else{
                                if (eventWithSubscription){
                                    eventWithSubscription = false;
                                }
                            }
                        }

                    }
                }
            }
        });

        for (var z=0; z < arrEvent.length; z++) {
            let event = arrEvent[z];
            if (Object.keys(event.item_meta_properties).length > 0) {
                for (var j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                    let keyMeta = Object.keys(event.item_meta_properties)[j];
                    let valueMetaTipology = Object.values(event.item_meta_properties)[j];
                    if (keyMeta === Utils.SlugTipology){
                        event.tipology = valueMetaTipology;
                        if (arrTipology.length > 0){
                            let result;
                            result = arrTipology.filter((valueTipology) => valueTipology.includes(valueMetaTipology));
                            if (result.length === 0){
                                arrTipology.push(valueMetaTipology);
                            }
                        }else{
                            arrTipology.push(valueMetaTipology);
                        }
                    }
                }
            }
        }

        if (arrEvent.length > 0){
            arrEvent.sort(function( a , b ){
                return a.date_from > b.date_from ? 1 : -1;
            });
        }

        return [
            {"arrEvent": arrEvent, "arrTipology" : arrTipology, "eventWithSubscription" : eventWithSubscription}
        ];

    }

}

export default Functions;
