import React from "react";
import ReactTooltip from 'react-tooltip';

//Popup, component when clicking on an element on the map
const Popup = ({ seat, isBooked, isSelected }) => {

    return (
        <ReactTooltip
            id="seat-tooltip"
            className="seat-tooltip"
            multiline
            delayHide={999999999999999}
            backgroundColor="#fff"
            textColor="#333"
        >
            <div
                style={{
                    padding: "10px",
                    borderRadius: "3px",
                    boxShadow: "0px 12px 76px 0 rgb(145 158 171 / 30%)",
                    backgroundColor: "white"
                }}
            >
                <div>Fila {seat?.row_number} - Posto {seat?.seat_number}</div>
                <div>{
                    isBooked ?
                        "Il posto è già prenotato!" : isSelected ? "Fai clic sul posto per deselezionare" :
                        "Fai clic sul posto per selezionare"
                }</div>
            </div>

        </ReactTooltip>
    );
};

export default Popup;
