import React, { Component,} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

// Elements
import Functions from "../../Functions";
import Messages from "../../Messages";
import DataManager from "../../DataManager";
import Utils from "../../Utils";

export default class ListSector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            msgError: null,
            msgErrorCart: "",
            isLoaded: false,
            isLoadedCart: false,
            itemsSelected: [],
            totItems: 0,
            sectorSelected: "",
            resultDataItems: [],
            resultDataQuotas: [],
            resultDataCategories: [],
            statusTicketSelected: true,
            showModalInfoEvent: false,
            arrEvents: [],
            arrSector: [],
            event: this.props.valuesData.event,
            organizer: this.props.valuesData.organizer
        };
        this.getSectorsByEvent(this.props.valuesData.event);

    }

    componentDidMount() {
        if (typeof(this.props.values) !== 'undefined' && this.state.values !== null) {
            if (this.props.values.ticketsSelected.length > 0) {
                this.setState({
                    totItems: this.props.values.totalAmount,
                    itemsSelected: this.props.values.ticketsSelected
                });
            }
        }
    }

    handleCloseInfoEvent = () => {
        this.setState({
            isLoadedCart: false,
            msgErrorCart: ""
        });
        this.setState({'showModalInfoEvent': false});
    };

    handleShowInfoEvent = () => {
        this.setState({'showModalInfoEvent': true});
    };

    getSectorsByEvent(event){
        let arrSlug = [];
        arrSlug.push(this.state.event.slug);

        DataManager.getAvailabilitySector(this.state.organizer.slug, arrSlug).then((result) => {
            if (result !== null) {
                const arrAvailability = result[0].availabilitySector;
                if (typeof (arrAvailability) !== 'undefined' && arrAvailability !== null) {
                    this.setState({
                        resultDataAvailabilityAllEvents: arrAvailability,
                        isLoaded: true
                    });

                    setTimeout(()=>{
                        this.setState({
                            arrSector: arrAvailability['seatsItemsModelMap']
                        });
                        this.getItemsByEvent(1, this.state.event);
                    },500)
                } else {
                    this.setState({
                        isLoaded: false,
                        msgError: Messages.MsgErrorNoSectors
                    });
                }
            }else{
                this.setState({
                    isLoaded: false,
                    msgError: Messages.MsgErrorGeneral
                });
            }
        });

    }

    getItemsByEvent(page, event) {
        DataManager.getItems(page, this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrItems = result[0].items;
                if (arrItems !== null && typeof (arrItems.results) !== 'undefined' && arrItems.results.length > 0) {
                    let dict = [];
                    arrItems.results.forEach((data) => {
                        dict.push(data);
                        this.setState({
                            resultDataItems: dict
                        });
                    });
                    if (typeof (arrItems.next) !== 'undefined' && arrItems.next != null) {
                        const page = arrItems.next.split('=').pop();
                        this.getItemsByEvent(page, event);
                    } else {
                        this.getCategoriesByEvent(event);
                    }
                } else {
                    Functions.alertMsgError(Messages.MsgErrorNoTicketsByEvent);
                }
            } else {
                Functions.alertMsgError(Messages.MsgErrorNoRetrieveTicketsByEvent);
            }
        });
    }

    getCategoriesByEvent(event) {
        DataManager.getCategories(this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrCategories = result[0].categories;
                if (arrCategories !== null && typeof (arrCategories.results) !== 'undefined' && arrCategories.results.length > 0) {
                    let dict = [];
                    arrCategories.results.forEach((data) => {
                        dict.push(data);
                        this.setState({
                            resultDataCategories: dict
                        });
                    });
                    /*this.setState({
                        isLoaded: true
                    });*/
                    this.getQuotasByEvent(event);
                } else {
                    this.setState({
                        isLoaded: false
                    });
                    Functions.alertMsgError(Messages.MsgErrorGeneral);
                }
            } else {
                this.setState({
                    isLoaded: false
                });
                Functions.alertMsgError(Messages.MsgErrorNoRetrieveCategoriesByEvent);
            }
        });
    }

    getQuotasByEvent(event) {
        DataManager.getQuotas(this.state.organizer.slug, event.slug).then((result) => {
            if (result !== null) {
                const arrQuotas = result[0].quotas;
                if (arrQuotas !== null && typeof (arrQuotas.results) !== 'undefined' && arrQuotas.results.length > 0) {
                    let dict = [];
                    arrQuotas.results.forEach((data) => {
                        dict.push(data);
                        this.setState({
                            resultDataQuotas: dict
                        });
                    });
                    this.setState({
                        isLoaded: true
                    });
                } else {
                    this.setState({
                        isLoaded: false
                    });
                    Functions.alertMsgError(Messages.MsgErrorGeneral);
                }
            } else {
                this.setState({
                    isLoaded: false
                });
                Functions.alertMsgError(Messages.MsgErrorNoRetrieveQuotasByEvent);
            }
        });
    }

    openModalwithItemsByEvent(e, sector){
        e.preventDefault();
        e.stopPropagation();
        if (this.state.arrSector[sector].itemHashMap !== null && this.state.arrSector[sector].itemHashMap.length > 0){
            this.setState({
                sectorSelected: sector
            });
            this.handleShowInfoEvent(true);
        }
    }

    addCartItem(e, objItem) {
        e.preventDefault();
        e.stopPropagation();
        const itemsSelected = this.state.itemsSelected;
        let idItem = objItem.id;

        if (this.state.event.jsonSettings.max_items_per_order === itemsSelected.length) {
            console.log("errore non puoi più aggiungere item");
        } else {
            /*let arrItems = [];
            arrItems = itemsSelected;
            objItem['nameSector'] = this.state.sectorSelected;
            arrItems.push(objItem);
            this.setState({
                itemsSelected: arrItems
            });
            this.sumItems(arrItems);*/

            let arrItems = [];
            arrItems = itemsSelected;
            objItem['nameSector'] = this.state.sectorSelected;
            var countItems = 0;

            if (arrItems.length > 0) {
                for (var i = 0; i < arrItems.length; i++) {
                    let item = arrItems[i];
                    if (item.id === idItem){
                        countItems = countItems + 1;
                    }
                }
            }

            this.checkQuota(arrItems, countItems, objItem);

        }

    }

    checkQuota(arrItems, countItems, objItem){
        let idItem = objItem.id;
        let totSector = this.state.arrSector[this.state.sectorSelected].seatList.length;

        for (var i = 0; i < this.state.resultDataQuotas.length; i++) {
            let obj = this.state.resultDataQuotas[i];
            const selectedItem = obj.items.find(element => element === idItem);

            if (typeof (selectedItem) !== 'undefined' && selectedItem !== null) {
                if (selectedItem === idItem){
                    if(typeof (obj.available_number) !== 'undefined' && obj.available_number === null){
                        if(countItems <= totSector){
                            arrItems.push(objItem);
                            this.setState({
                                itemsSelected: arrItems
                            });
                            this.sumItems(arrItems);
                        }else{
                            this.setState({
                                isLoadedCart: true,
                                msgErrorCart: Messages.MsgErrorTotTicketsBySector
                            });
                        }
                    }else{
                        if (obj.available_number > 0 && obj.available_number <= obj.size ){
                            if(obj.available_number < totSector){

                                if(countItems < obj.available_number){
                                    arrItems.push(objItem);
                                    this.setState({
                                        itemsSelected: arrItems
                                    });
                                    this.sumItems(arrItems);
                                }else{
                                    this.setState({
                                        isLoadedCart: true,
                                        msgErrorCart: Messages.MsgErrorAddTicketsInCartLimit
                                    });
                                }
                            }else{

                                //if(obj.available_number > totSector && countItems <= totSector){
                                if(countItems <= totSector){
                                    arrItems.push(objItem);
                                    this.setState({
                                        itemsSelected: arrItems
                                    });
                                    this.sumItems(arrItems);
                                }else{
                                    this.setState({
                                        isLoadedCart: true,
                                        msgErrorCart: Messages.MsgErrorTotTicketsBySector
                                    });
                                }
                            }
                        }else{
                            this.setState({
                                isLoadedCart: true,
                                msgErrorCart: Messages.MsgErrorAddTicketsInCartOutStock
                            });
                        }
                    }
                }
            }

        }
    }

    sumItems(arrItems){
        if (arrItems.length > 0) {
            let totFloatPriceItem = 0;
            for (var i = 0; i < arrItems.length; i++) {
                let objItem = arrItems[i];
                let presale = 0.0;
                let presaleFixed = 0.0;
                if (typeof (objItem.bundles) !== 'undefined' && objItem.bundles !== null && objItem.bundles.length > 0) {
                    presale = objItem.bundles[0].designated_price * 100;
                    presaleFixed = parseFloat(presale).toFixed(0);
                }
                let v1 = this.state.totItems * 100;
                let v2 = objItem.default_price * 100;
                let totalV1V2 = (Number(v1) + Number(v2));
                let tot = (totalV1V2 + Number(presaleFixed)) / 100;
                totFloatPriceItem = parseFloat(tot).toFixed(2);
                this.setState({
                    totItems: totFloatPriceItem
                });
            }
            this.sendTickets(totFloatPriceItem, arrItems);
        }
        this.setState({
            statusTicketSelected: true
        });
    }

    removeCartItem(e, objItem) {
        e.preventDefault();
        e.stopPropagation();
        const itemsSelected = this.state.itemsSelected;
        let foundItem = false;
        let totFloatPriceItem = 0;
        if (itemsSelected.length > 0) {
            for (var i = 0; i < itemsSelected.length; i++) {
                if (itemsSelected[i].id === objItem.id) {
                    foundItem = true;
                }
            }
            if (foundItem) {
                const idsItemsSelected = itemsSelected.slice();
                const selectedItem = idsItemsSelected.find(element => element.id === objItem.id);
                idsItemsSelected.splice(idsItemsSelected.indexOf(selectedItem), 1);
                let presale = 0.0;
                let presaleFixed = 0.0;
                if (typeof (objItem.bundles) !== 'undefined' && objItem.bundles !== null && objItem.bundles.length > 0) {
                    presale = objItem.bundles[0].designated_price * 100;
                    presaleFixed = parseFloat(presale).toFixed(0);
                }
                let v1 = this.state.totItems * 100;
                let v2 = objItem.default_price * 100;
                let totalV1V2 = (Number(v1) - Number(v2));
                let tot = (totalV1V2 - Number(presaleFixed)) / 100;
                totFloatPriceItem = parseFloat(tot).toFixed(2);
                this.setState({
                    totItems: totFloatPriceItem
                });
                this.setState({
                    itemsSelected: idsItemsSelected
                });
                this.sendTickets(totFloatPriceItem, idsItemsSelected);
            }
        }
        this.setState({
            isLoadedCart: false,
            msgErrorCart: ""
        });
    }

    sendTickets(totFloatPriceItem, arrTickets){
        this.props.sendTickets(arrTickets, totFloatPriceItem);
    }

    totalItems(objItem) {
        let totItems = 0;
        for (var i = 0; i < this.state.itemsSelected.length; i++) {
            let item = this.state.itemsSelected[i];
            if (item.id === objItem.id) {
                totItems = totItems + 1;
            }
        }
        return totItems;
    }

    renderButtonsAddAndRemoveWithTotal(objItem){
        return (
            <div className="buttons-cart">
                <Button
                    variant="outline-primary"
                    size="small"
                    onClick={(e) => this.removeCartItem(e, objItem)}
                >
                    <i className="fas fa-minus"/>
                </Button>
                <p className="margin-top-event">{this.totalItems(objItem)}</p>
                <Button
                    variant="outline-primary"
                    size="small"
                    onClick={(e) => this.addCartItem(e, objItem)}
                >
                    <i className="fas fa-plus"/>
                </Button>
            </div>
        );
    }

    renderQuotasForEvent(objItem) {
        let idItem = objItem.id;

        for (var i = 0; i < this.state.resultDataQuotas.length; i++) {
            let obj = this.state.resultDataQuotas[i];
            const selectedItem = obj.items.find(element => element === idItem);
            if (typeof (selectedItem) !== 'undefined' && selectedItem !== null) {
                if (selectedItem === idItem) {
                    if (obj.available_number === null){
                        return (
                            <p className="text-success margin-bottom-text-danger">Biglietti illimitati</p>
                        );
                    }else{
                        if (obj.available_number > 0 && obj.available_number <= obj.size){
                            return (
                                <p className="text-success margin-bottom-text-danger">Sono disponibili {obj.available_number} biglietti</p>
                            );
                        }else{
                            return (
                                <p className="text-danger margin-bottom-text-danger">Biglietti esauriti</p>
                            );
                        }
                    }
                }
            }
            /*if (obj.id === idItem){
                if (obj.available_number > 0 && obj.available_number <= obj.size){
                    return (
                        <p className="text-success margin-bottom-text-danger">Sono disponibili {obj.available_number} biglietti</p>
                    );
                }else{
                    return (
                        <p className="text-danger margin-bottom-text-danger">Biglietti esauriti</p>
                    );
                }
            }*/
        }
    }

    renderSingleItem(item) {

        const arrObj = [];

        if (item === this.state.sectorSelected){
            let arr = this.state.arrSector[item].itemHashMap;

            if (arr != null){
                arr.map((objItem) => {
                    if (typeof (objItem.sales_channels) !== 'undefined' && objItem.sales_channels !== null && objItem.sales_channels.length > 0) {
                        const checkWeb = objItem.sales_channels.every(item => item === Utils.KEY_WEB);
                        if (checkWeb){
                            let presale = 0;
                            let bundleType = "";
                            if (typeof (objItem.bundles) !== 'undefined' && objItem.bundles !== null && objItem.bundles.length > 0) {
                                let designatedPrice = objItem.bundles[0].designated_price * 100;
                                presale = parseFloat(designatedPrice/100).toFixed(2);
                                if (typeof (objItem.bundles[0].bundle_type) !== 'undefined' &&  objItem.bundles[0].bundle_type !== null) {
                                    bundleType = objItem.bundles[0].bundle_type + ":";
                                }
                            }

                            let name = objItem.name.it;
                            let nameProduct = Functions.nameProduct(name);

                            arrObj.push(<div>
                                <div className="information-cart">
                                    <p>{nameProduct}</p>
                                    <p>Prezzo: <strong>{objItem.default_price}€</strong></p>
                                    <p>{presale > 0 ? bundleType + " " : "     "} <strong>{presale > 0 ? presale + "€" : ""}</strong></p>
                                </div>
                                {this.renderButtonsAddAndRemoveWithTotal(objItem)}
                                {this.renderQuotasForEvent(objItem)}
                            </div>);
                        }
                    }
                })
            }
        }

        return arrObj;

    }

    renderItems() {
        return (
            <div>
                <h5>Totale: {this.state.totItems}€</h5>
                {Object.keys(this.state.arrSector).map((objItem) => (
                    <div>
                        {this.renderSingleItem(objItem)}
                    </div>
                ))}
                <div hidden={!this.state.isLoadedCart}><p className="text-color-secondary title-notice">{this.state.msgErrorCart}</p></div>
            </div>
        );
    }

    renderTotBadgeByCategory(sector) {
        if(this.state.itemsSelected.length > 0){
            var idCategory = 0;
            var countItemsSelected = 0;

            for (var i = 0; i < this.state.resultDataCategories.length; i++) {
                let objCategory = this.state.resultDataCategories[i];
                if (objCategory.name.it === sector){
                    idCategory = objCategory.id;
                }
            }

            for (var z = 0; z < this.state.itemsSelected.length; z++) {
                let objItem = this.state.itemsSelected[z];
                if (objItem.category === idCategory){
                    countItemsSelected = countItemsSelected + 1;
                }
            }

            return (
                <Badge bg="info" pill>
                    {countItemsSelected}
                </Badge>
            );
        }
    }

    renderEventInModal() {
        let tot = 0;
        tot = this.state.event.jsonSettings.max_items_per_order - this.state.itemsSelected.length;

        return (
            <Modal
                show={this.state.showModalInfoEvent}
                onHide={this.handleCloseInfoEvent}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{"zIndex": "10000000", "overflow-y": "auto", "height": "90vh"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Hai selezionato il settore {this.state.sectorSelected}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <div style={{"overflow-y": "auto", "height": "60vh"}}>
                        <section className="section-area section-sp6 appointment-wraper">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-6 col-md-6">
                                        <div className="individual-form form-wraper">
                                            <h4 className="text-color-secondary">Seleziona biglietto</h4>
                                            <p className="text-danger margin-bottom-text-danger">Puoi
                                                selezionare {tot} biglietto/i</p>
                                            {this.renderItems()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }

    render() {
        const { msgError, isLoaded, totItems} = this.state;

        if (msgError) {
            return <div>Errore: {msgError}</div>;
        } else if (!isLoaded) {
            return <div><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>;
        }else {
            return(
                <>
                    <div>
                        <div className="form-card">
                            <div className='align-two-text-flex'>
                                <h2 className="fs-title">Settori</h2>
                                <h3 className="fs-title-secondary-18 text-color-secondary mt-">Totale: {totItems} €</h3>
                            </div>
                            <ListGroup>
                                {Object.keys(this.state.arrSector).map((sector, idx) => (
                                    <ListGroup.Item
                                        onClick={(e) => this.openModalwithItemsByEvent(e, sector)}
                                        as="li"
                                        className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{sector}</div>
                                            {
                                                this.state.arrSector[sector].seatList.length > 0
                                                    ?(
                                                        <p className="size-value-row size-inline-row text-success">Disponibili</p>
                                                    )
                                                    : <p className="size-value-row size-inline-row text-danger">Non disponibili</p>
                                            }
                                        </div>
                                        {this.renderTotBadgeByCategory(sector)}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <p className="title-notice">* Puoi selezionare fino ad un max di {this.state.event.jsonSettings.max_items_per_order} biglietto/i fra tutti i settori</p>
                        </div>
                        {this.renderEventInModal()}
                    </div>
                </>
            );
        }


    }

}
