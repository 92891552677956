import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Seo from "../elements/seo";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";

//Error, Error 404 page
class Error extends Component{
	
	render(){
		let dict = {};
		dict["title"] = Utils.NAME_CUSTOMER + " | Errore 404";
		dict["description"] = Utils.NAME_CUSTOMER + " | Errore 404";

		return (
			<>
				<Seo propsSeo={dict}></Seo>
				<Header />
				
				<div className="page-content bg-white">
					
					<section className="section-area section-sp2 error-404">
						<div className="container">
							<div className="inner-content">
								<h2 className="error-title">4<span></span>4</h2>
								<h3 className="error-text">Impossibile trovare la Pagina che stavi cercando.</h3>
								<p>La pagina che stai cercando potrebbe essere stata rimossa, il suo nome è cambiato o è temporaneamente non disponibile.</p>
								<div className="clearfix">
									<Link to="/" className="btn btn-primary">Home</Link>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Error;